<template>
    <v-data-table
        :headers="headers"
        :items="list"
        no-data-text="이미지 제보 내역이 존재하지 않습니다."
        :footer-props="{
            'items-per-page-options': [5, -1],
            'items-per-page-text':'페이지당 이미지 제보 수'
        }"
        mobile-breakpoint="0"
    >
        <template v-slot:item="{ item }">
            <tr
                style="cursor:pointer;"
                @click="$router.push('/card/detail?id='+item.card_id)"
            >
                <td style="width:88px" class="pa-2">
                    <v-img 
                        width="80"
                        height="110"
                        :src="'/upload/card_'+item.card_nation+'_image/'+encodeURI(item.image)"
                    ></v-img>
                </td>
                <td style="width:172px">
                    {{item.card_id}}<br/>
                    <span v-if="item.card_nation == 'kr'">국내판</span>
                    <span v-if="item.card_nation == 'en'">북미판</span>
                    <span v-if="item.card_nation == 'jp'">일본판</span>
                </td>
                <td style="width:300px">{{item.reason}}</td>
                <td style="width:100px">{{item.status}}</td>
                <td style="width:130px">{{new Date(item.created).toLocaleDateString()}}</td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: ['user_id'],

    data: () => ({
        headers: [
            { text: '이미지', value: 'image' },
            { text: '카드 ID/국가', value: 'card_id' },
            { text: '반려사유', value: 'reason' },
            { text: '상태', value: 'status' },
            { text: '제보일자', value: 'created' }
        ],
        list: []
    }),

    mounted() {
        // 목록
        this.load()
    },

    watch: {
        user_id: {
            deep:true,
            handler(newData) {
                this.load()
            }
        }
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/mypage/list/provide/image', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>