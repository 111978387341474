<template>
    <div>
        <!-- 마이페이지 기본 헤더 -->
        <DefaultHeader />

        <!-- 본문 -->
        <v-sheet class="py-14 px-4 rounded-15">
            <v-sheet width="276" class="mx-auto">
                <!-- 아이디 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="myImport.user_id"
                    label="아이디"
                    class="rounded-15 font-weight-bold mb-4"
                    height="56"
                    readonly
                ></v-text-field>

                <!-- 가입일자 -->
                <v-text-field
                    v-if="myImport.created"
                    dense
                    outlined
                    hide-details
                    v-model="computedCreated"
                    label="가입일자"
                    class="rounded-15 font-weight-bold mb-4"
                    height="56"
                    readonly
                ></v-text-field>

                <!-- 이메일 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="myImport.email"
                    label="이메일"
                    class="rounded-15 font-weight-bold mb-4"
                    height="56"
                    readonly
                ></v-text-field>

                <!-- 포인트 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="myImport.point"
                    label="포인트"
                    class="rounded-15 font-weight-bold mb-4"
                    height="56"
                    readonly
                ></v-text-field>

                <!-- 회원그룹 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="myImport.type"
                    label="회원그룹"
                    class="rounded-15 font-weight-bold mb-4"
                    height="56"
                    readonly
                ></v-text-field>

                <!-- 닉네임 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="myImport.nickname"
                    label="닉네임"
                    class="nickname rounded-15 font-weight-bold mb-4"
                    height="56"
                    readonly
                ></v-text-field>

                <v-checkbox
                    class="ml-16"
                    dense
                    hide-details
                    v-model="myImport.email_agree"
                    label="이메일 수신동의"
                    @change="emailAgreeChanged()"
                ></v-checkbox>

                <!-- Submit 버튼 -->
                <!-- <v-sheet class="d-flex justify-center mt-10">
                    <v-btn
                        class="rounded-10 font-weight-bold"
                        style="font-size:18px;"
                        width="173"
                        height="56"
                        dark
                        color="#615DFA"
                        depressed
                        @click="submit()"
                    >
                        닉네임 변경
                    </v-btn>
                </v-sheet> -->
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
// 마이페이지 기본 헤더
import DefaultHeader from './components/DefaultHeader'
import { mapMutations } from 'vuex'

export default {
    components: {
        DefaultHeader
    },

    data: () => ({
        myImport: {},
        ready_nick:""
    }),

    mounted(){
        this.$http.post('/api/user/select/specific', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            this.myImport = res.data[0]
        })
    },

    computed: {
        computedCreated(){
            if(this.myImport.created){
                return new Date(this.myImport.created).toLocaleString()
            }else{
                return null
            }
        }
    },

    methods: {
        ...mapMutations(['nicknameUpdate']),

        // 닉네임 중복 확인
        nick_check(){
            this.$http.post('/api/user/select/nickname/check', {
                params: {
                    nickname: this.change_nickname
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("중복되지 않은 닉네임입니다.")
                    this.ready_nick = this.change_nickname
                }else{
                    alert("이미 해당 닉네임이 존재합니다. 다른 닉네임을 시도해주세요.")
                }
            })
        },

        // 프로필 수정 제출하기
        submit(){
            // 공백 또는 특수문자를 확인하는 정규 표현식
            const regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

            if(this.myImport.nickname == "")
            {
                alert("변경할 닉네임을 입력해주세요")
            }
            else if(regex.test(this.myImport.nickname))
            {
                alert("변경할 닉네임에 공백 또는 특수문자가 입력될 수 없습니다")
            }
            else
            {
                this.$http.post('/api/user/select/nickname/check', {
                    params: {
                        nickname: this.myImport.nickname
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 해당 닉네임이 존재합니다. 다른 닉네임을 시도해주세요.")
                    }else{
                        this.$http.post('/api/user/update/nickname', {
                            params: {
                                change_nickname: this.myImport.nickname,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            // $store 업데이트
                            this.nicknameUpdate(this.myImport)

                            alert("닉네임이 수정되었습니다.")
                        })
                    }
                })
            }
        },

        emailAgreeChanged(){
            this.$http.post('/api/user/update/email_agree', {
                params: {
                    user_id: this.$store.state.user_id,
                    email_agree: this.myImport.email_agree
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    if(this.myImport.email_agree){
                        alert("이메일 수신동의가 동의처리되었습니다")
                        this.$http.post('/api/user/select/specific', {
                            params: {
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            if(!res.data[0].email_agree_first){
                                this.$http.post('/api/user/update/email_agree_first', {
                                    params: {
                                        user_id: this.$store.state.user_id
                                    }
                                }).then((res) => {
                                    if(res.data.affectedRows){
                                        // 포인트 내역 기록
                                        this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                                            params: {
                                                user_id: this.$store.state.user_id,
                                                title: "첫 이메일 수신동의",
                                                menu: '회원정보 수정',
                                                activity: '첫 이메일 수신동의',
                                                pay_point: 1000,
                                            }
                                        }).then((res) => {
                                            if(res.data.affectedRows){
                                                alert("첫 이메일 수신동의로 1,000포인트가 지급되었습니다.")
                                            }
                                        })

                                        // 포인트 지급
                                        this.$http.post('/api/mypage/address/point/plus', {
                                            params: {
                                                point: 1000,
                                                user_id: this.$store.state.user_id
                                            }
                                        })

                                        // 첫 수신동의 체크
                                        this.$http.post('/api/user/update/email_agree_first', {
                                            params: {
                                                user_id: this.$store.state.user_id
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }else{
                        alert("이메일 수신동의가 거부처리되었습니다")
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #23D2E2;
}
.v-text-field--outlined >>> label {
    color: #23D2E2;
    font-size:16px;
    font-weight:400;
}

::v-deep .v-text-field input {
    text-align: center;
    font-size: 13px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 13px;
}
</style>
<style scoped>
.nickname >>> fieldset {
  border-color: #E0E0EB !important;
}
.nickname >>> label {
    color: #615DFA !important;
    font-size:16px;
    font-weight:400;
}
</style>