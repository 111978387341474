<template>
    <div>
        <!-- 대화 내역 -->
        <div class="my-4" style="min-height:440px; max-height:620px; overflow-y:auto" ref="drawContainer">
            <v-sheet class="mx-2" v-for="(item, index) in list" :key="index">
                <v-sheet class="d-flex align-end mt-4" :class="item.sender==$store.state.user_id? 'justify-end':'justify-start'">
                    <!-- 메세지 시간 -->
                    <v-sheet v-if="item.sender==$store.state.user_id">
                        <p class="mb-0 mr-2 text-end" style="font-size:9px; color:#B3B3C2;">
                            {{new Date(item.created).toLocaleDateString()}}
                        </p>
                        <p class="mb-0 mr-2 text-end" style="font-size:9px; color:#B3B3C2;">
                            {{new Date(item.created).toLocaleTimeString()}}
                        </p>
                    </v-sheet>

                    <!-- 일반 메세지 -->
                    <v-sheet
                        v-if="item.type=='message'"
                        class="px-4 py-2 rounded-10"
                        style="word-break: break-all;"
                        :style="item.sender==$store.state.user_id? 'background:#23D2E2; color:white;':'background:#E8E9F1'"
                        max-width="60%"
                    >
                        {{item.content}}
                    </v-sheet>

                    <!-- 일반 메세지 -->
                    <v-sheet
                        v-if="item.type=='image'"
                        class="pa-3 rounded-10"
                        :style="item.sender==$store.state.user_id? 'background:#23D2E2; color:white;':'background:#E8E9F1'"
                        max-width="60%"
                    >
                        <v-img
                            style="border-radius:10px;"
                            :src="'/upload/message/'+item.content"
                        ></v-img>
                    </v-sheet>

                    <!-- 거래요청 메세지 -->
                    <v-sheet
                        v-if="item.type=='order'"
                        class="px-6 py-4 rounded-10"
                        outlined
                        width="248"
                    >
                        <p
                            class="font-weight-bold text-center mb-2"
                            style="font-size:15px;"
                        >
                            카드거래를 요청합니다
                        </p>
                        <p
                            class="font-weight-bold text-center grey--text text--darken-1 mb-3"
                            style="font-size:13px;"
                        >
                            {{item.first_card_name}}<span v-if="item.rest_card_count">외{{item.rest_card_count}}건</span>
                        </p>
                        
                        <v-btn
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            dark
                            color="primary"
                            depressed
                            @click="open_order(item.order_id)"
                        >
                            요청한 내용 확인
                        </v-btn>

                        <div v-if="item.sender==$store.state.user_id">
                            <v-btn
                                class="rounded-10 mb-2"
                                width="200"
                                height="40"
                                color="#1DA1F2"
                                dark
                                depressed
                            >
                                거래요청중
                            </v-btn>
                            <v-btn
                                class="rounded-10"
                                width="200"
                                height="40"
                                color="#F8468D"
                                dark
                                depressed
                                @click="cancel_order(item)"
                            >
                                거래요청 취소하기
                            </v-btn>
                        </div>

                        <div v-if="!(item.sender==$store.state.user_id)">
                            <v-btn
                                class="rounded-10 mb-2"
                                width="200"
                                height="40"
                                color="#1DA1F2"
                                dark
                                depressed
                                @click="accept_order(item)"
                            >
                                거래 수락하기
                            </v-btn>
                            <v-btn
                                class="rounded-10"
                                width="200"
                                height="40"
                                color="#F8468D"
                                dark
                                depressed 
                                @click="refuse_order(item)"
                            >
                                거래 거절하기
                            </v-btn>
                        </div>
                    </v-sheet>

                    <!-- 거래취소 메세지 -->
                    <v-sheet
                        v-if="item.type=='order_canceled'"
                        class="px-6 py-4 rounded-10"
                        outlined
                        width="248"
                    >
                        <p
                            class="font-weight-bold text-center mb-2"
                            style="font-size:15px;"
                        >
                            카드거래가 취소되었습니다
                        </p>
                        <p
                            class="font-weight-bold text-center grey--text text--darken-1 mb-3"
                            style="font-size:13px;"
                        >
                            {{item.first_card_name}}<span v-if="item.rest_card_count">외{{item.rest_card_count}}건</span>
                        </p>
                        <v-btn
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="primary"
                            dark
                            depressed
                            @click="open_order(item.order_id)"
                        >
                            요청 내용 확인
                        </v-btn>
                        <v-btn
                            class="rounded-10"
                            width="200"
                            height="40"
                            color="#ADAFCA"
                            dark
                            depressed
                        >
                            거래가 종료되었습니다
                        </v-btn>
                    </v-sheet>

                    <!-- 거래거절 메세지 -->
                    <v-sheet
                        v-if="item.type=='order_refused'"
                        class="px-6 py-4 rounded-10"
                        outlined
                        width="248"
                    >
                        <p
                            class="font-weight-bold text-center mb-2"
                            style="font-size:15px;"
                        >
                            카드거래가 거절되었습니다
                        </p>
                        <p
                            class="font-weight-bold text-center grey--text text--darken-1 mb-3"
                            style="font-size:13px;"
                        >
                            {{item.first_card_name}}<span v-if="item.rest_card_count">외{{item.rest_card_count}}건</span>
                        </p>
                        <v-btn
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="primary"
                            dark
                            depressed
                            @click="open_order(item.order_id)"
                        >
                            요청 내용 확인
                        </v-btn>
                        <v-btn
                            class="rounded-10"
                            width="200"
                            height="40"
                            color="#ADAFCA"
                            dark
                            depressed
                        >
                            거래가 종료되었습니다
                        </v-btn>
                    </v-sheet>

                    <!-- 거래진행중 메세지 -->
                    <v-sheet
                        v-if="item.type=='order_progress'"
                        class="px-6 py-4 rounded-10"
                        outlined
                        width="248"
                    >
                        <p
                            class="font-weight-bold text-center mb-2"
                            style="font-size:15px;"
                        >
                            진행중인 카드거래
                        </p>
                        <p
                            class="font-weight-bold text-center grey--text text--darken-1 mb-3"
                            style="font-size:13px;"
                        >
                            {{item.first_card_name}}<span v-if="item.rest_card_count">외{{item.rest_card_count}}건</span>
                        </p>
                        <v-btn
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="primary"
                            dark
                            depressed
                            @click="open_order(item.order_id)"
                        >
                            요청 내용 확인
                        </v-btn>

                        <!-- 거래 완료하기 (구매자) -->
                        <v-btn
                            v-if="item.sender==$store.state.user_id"
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="#1DA1F2"
                            dark
                            depressed
                            @click="buyer_finish_trade(item)"
                        >
                            거래 완료하기
                        </v-btn>

                        <!-- 거래 진행중 (판매자) -->
                        <v-btn
                            v-if="item.sender!=$store.state.user_id"
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="#1DA1F2"
                            dark
                            depressed
                        >
                            거래 진행중
                        </v-btn>

                        <v-btn
                            class="rounded-10"
                            width="200"
                            height="40"
                            color="#F8468D"
                            dark
                            depressed
                            @click="cancel_order(item)"
                        >
                            거래 취소하기
                        </v-btn>
                    </v-sheet>

                    <!-- 거래완료(리뷰 전) 메세지 -->
                    <v-sheet
                        v-if="item.type=='order_finished'"
                        class="px-6 py-4 rounded-10"
                        outlined
                        width="248"
                    >
                        <p
                            class="font-weight-bold text-center"
                            style="font-size:15px;"
                        >
                            카드거래 완료
                        </p>
                        <p
                            class="font-weight-bold text-center grey--text text--darken-1 mb-3"
                            style="font-size:13px;"
                        >
                            {{item.first_card_name}}<span v-if="item.rest_card_count" class="grey--text text--darken-1">외 {{item.rest_card_count}}건</span>
                        </p>
                        <v-btn
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="primary"
                            dark
                            depressed
                            @click="open_order(item.order_id)"
                        >
                            거래 내용 확인
                        </v-btn>

                        <!-- 리뷰 작성하기 (구매자) -->
                        <v-btn
                            v-if="item.sender==$store.state.user_id"
                            class="rounded-10"
                            width="200"
                            height="40"
                            color="#F8468D"
                            dark
                            depressed
                            @click="open_review(item)"
                        >
                            매너 점수 / 리뷰 작성
                        </v-btn>

                        <!-- 리뷰 작성전 (판매자) -->
                        <v-btn
                            v-if="item.sender!=$store.state.user_id"
                            class="rounded-10"
                            width="200"
                            height="40"
                            color="#F8468D"
                            dark
                            depressed
                        >
                            리뷰 작성전
                        </v-btn>
                    </v-sheet>

                    <!-- 거래완료(리뷰 후) 메세지 -->
                    <v-sheet
                        v-if="item.type=='review_finished'"
                        class="px-6 py-4 rounded-10"
                        outlined
                        width="248"
                    >
                        <p
                            class="font-weight-bold text-center mb-2"
                            style="font-size:15px;"
                        >
                            카드거래 완료
                        </p>
                        <p
                            class="font-weight-bold text-center grey--text text--darken-1 mb-3"
                            style="font-size:13px;"
                        >
                            {{item.first_card_name}} <span v-if="item.rest_card_count" class="grey--text text--darken-1">외 {{item.rest_card_count}}건</span>
                        </p>
                        <v-btn
                            class="rounded-10 mb-2"
                            width="200"
                            height="40"
                            color="primary"
                            dark
                            depressed
                            @click="open_order(item.order_id)"
                        >
                            거래 내용 확인
                        </v-btn>
                        <v-btn
                            class="rounded-10"
                            width="200"
                            height="40"
                            color="#ADAFCA"
                            dark
                            depressed
                        >
                            리뷰 작성 완료
                        </v-btn>
                    </v-sheet>

                    <!-- 메세지 시간 -->
                    <v-sheet v-if="!(item.sender==$store.state.user_id)">
                        <p class="mb-0 ml-2" style="font-size:9px; color:#B3B3C2;">
                            {{new Date(item.created).toLocaleDateString()}}
                        </p>
                        <p class="mb-0 ml-2" style="font-size:9px; color:#B3B3C2;">
                            {{new Date(item.created).toLocaleTimeString()}}
                            <v-icon
                                v-if="item.type=='message'"
                                v-ripple="false"
                                title="메세지 신고하기"
                                size="12"
                                class="ml-2px mb-2px"
                                color="#B3B3C2"
                                style="background-color:none; cursor:pointer;"
                                @click="report_message_open(item)"
                            >
                                mdi-alert-circle
                            </v-icon>
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- 메시지 입력 -->
        <v-sheet class="px-2 pb-2 mt-6 d-flex align-end">
            <v-sheet outlined class="rounded-10 pa-1" min-height="48" width="100%">
                <v-textarea
                    rows="1"
                    auto-grow
                    dense
                    solo
                    flat
                    hide-details
                    style="font-size:15px; font-weight:bold;"
                    placeholder="메시지를 입력해주세요"
                    v-model="message"
                    @keyup.enter="submit()"
                ></v-textarea>
            </v-sheet>
            <v-btn
                class="ml-1 rounded-10"
                height="48"
                width="64"
                depressed
                dark
                color="#615DFA"
                @click="submit()"
            >
                보내기
            </v-btn>
            <v-btn
                class="ml-1 rounded-10"
                height="48"
                width="54"
                small
                depressed
                dark
                color="#615DFA"
                @click="dialog.imageMessage = true"
            >
                <v-icon size="28">mdi-image-outline</v-icon>
            </v-btn>
        </v-sheet>

        <!-- 거래요청 내용 확인 (dialog) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.order"
            width="830"
            content-class="rounded-15"
        >
            <v-sheet class="rounded-15 pa-4">
                <v-sheet class="pb-4" style="position:relative;">
                    <v-btn
                        width="140"
                        height="37"
                        dark
                        depressed
                        color="#23D2E2"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px; position:absolute; top:16px; left:12px;"
                        :to="'/trade/post/read?id='+order_detail.post_id"
                    >
                        거래글로 이동
                    </v-btn>
                    <p
                        class="font-weight-bold py-4 ma-0 text-center"
                        style="font-size:26px;"
                    >
                        거래요청 상세내용
                    </p>
                    <v-btn
                        width="140"
                        height="37"
                        dark
                        depressed
                        color="#23D2E2"
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px; position:absolute; top:16px; right:12px;"
                    >
                        {{order_detail.state}}
                    </v-btn>
                </v-sheet>

                <v-sheet class="pa-2" v-if="deck_list.length">
                    <table class="cardList">
                        <tr>
                            <th>사진</th>
                            <th>이미지</th>
                            <th>이름</th>
                            <th>상태</th>
                            <th>가격</th>
                        </tr>
                        <tr v-for="item in deck_list" :key="item.id">
                            <td width="80" class="pl-3">
                                <v-img
                                    @click="selected_item = item; dialog.image = true"
                                    style="cursor:pointer;"
                                    width="48"
                                    :src="'/upload/trade/deck/'+item.deck_image"
                                ></v-img>
                            </td>
                            <td width="76" style="cursor:pointer" @click="goToDetail(item.deck_id)">
                                <!-- <v-img width="48" src="@/assets/default.png"></v-img> -->
                                <v-img
                                    width="48" 
                                    :src="computed_image(item)"
                                ></v-img>
                            </td>
                            <td style="cursor:pointer" @click="goToDetail(item.deck_id)">
                                <p class="mb-0">{{item.deck_name}}</p>
                            </td>
                            <td width="120" class="text-center">{{item.deck_state}}</td>
                            <td width="200" class="text-center">{{$toComma(item.deck_price)}}원</td>
                        </tr>
                    </table>
                </v-sheet>

                <v-sheet class="pa-2">
                    <table class="cardList">
                        <tr>
                            <th>이미지</th>
                            <th>이름</th>
                            <th>상태</th>
                            <th>가격</th>
                        </tr>
                        <tr v-for="item in card_list" :key="item.id">
                            <td width="76" style="cursor:pointer">
                                <v-img
                                    v-if="item.card_nation=='en' && item.image_en" 
                                    width="48" 
                                    :src="'/upload/card_en_image/'+encodeURI(item.image_en)"
                                ></v-img>
                                <v-img 
                                    v-if="item.card_nation=='kr' && item.image_kr"
                                    width="48" 
                                    :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)"
                                ></v-img>
                                <v-img 
                                    v-if="item.card_nation=='jp' && item.image_jp" 
                                    width="48" 
                                    :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)"
                                ></v-img>
                            </td>
                            <td style="cursor:pointer">
                                <p class="mb-0">{{item.card_name}}</p>
                                <p class="mb-0 text-caption">
                                    <v-icon small style="margin-bottom:2px;" class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                    <font v-if="item.card_nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                    <font v-if="item.card_nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                    <font v-if="item.card_nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                    <font v-if="item.card_nation=='en' && item.printedTotal"> / {{item.printedTotal.replaceAll("\"", "")}}</font>
                                <font v-if="item.card_nation=='kr' && item.set_printedTotal_kr"> / {{item.set_printedTotal_kr}}</font>
                                <font v-if="item.card_nation=='jp' && item.set_printedTotal_jp"> / {{item.set_printedTotal_jp}}</font>
                                </p>
                                <p v-if="item.card_nation=='en' && item.set_en" class="text-caption ml-1 mb-0">{{item.set_en}}</p>
                                <p v-if="item.card_nation=='kr' && item.set_kr" class="text-caption ml-1 mb-0">{{item.set_kr}}</p>
                                <p v-if="item.card_nation=='jp' && item.set_jp" class="text-caption ml-1 mb-0">{{item.set_jp}}</p>
                            </td>
                            <td width="140">{{item.card_state}}</td>
                            <td width="120">{{$toComma(item.card_price)}}원</td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet>
                    <p class="text-right mr-2 mb-1" style="font-size:13px;">
                        <span class="mr-2">거래방법</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{order_detail2.trade_way}}</span>

                        <span class="mr-2">총 수량</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{card_list.length}}개</span>

                        <span class="mr-2">총 가격</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{$toComma(selectedSum)}}원</span>

                        <span class="mr-2">배송비</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{order_detail2.delivery_price}}원</span>
                    </p>

                    <p class="text-right mr-2 mb-2" style="font-size:13px;">
                        <span class="mr-2">주소</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{order_detail2.seller_address}}</span>

                        <span class="mr-2">전화번호</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{seller_phone}}</span>
                    </p>
                </v-sheet>
                <v-divider class="mx-2"></v-divider>
                <v-sheet class="mt-4 pb-6">
                    <p class="text-right mr-6" style="font-size:13px;">
                        <span class="mr-2">구매자 배송지</span>
                        <span class="font-weight-bold" style="color:#615DFA;">{{order_detail.shipping_address}}</span>

                        <span class="ml-2 mr-2">구매자 전화번호</span>
                        <span class="font-weight-bold" style="color:#615DFA;">{{buyer_phone}}</span>
                    </p>
                    <div
                        class="pa-4"
                        style="font-size:16px; white-space:pre-line;"
                        v-html="order_detail.content"
                    ></div>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 거래요청 내용 확인 (dialog) (Mobile) -->
        <v-dialog
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.order"
            content-class="rounded-15"
        >
            <v-sheet class="rounded-15 pa-4">
                <p
                    class="font-weight-bold py-4 ma-0 text-center"
                    style="font-size:26px;"
                >
                    거래요청 상세내용
                </p>
                <v-sheet class="pb-4 d-flex justify-center">
                    <v-btn
                        width="100"
                        height="37"
                        dark
                        depressed
                        color="#23D2E2"
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:12px;"
                        :to="'/trade/post/read?id='+order_detail.post_id"
                    >
                        거래글로 이동
                    </v-btn>
                    <v-btn
                        width="100"
                        height="37"
                        dark
                        depressed
                        color="#23D2E2"
                        class="rounded-10 font-weight-bold"
                        style="font-size:12px;"
                    >
                        {{order_detail.state}}
                    </v-btn>
                </v-sheet>

                <v-sheet class="pa-2" v-if="deck_list.length">
                    <table class="cardList">
                        <tr>
                            <th>사진</th>
                            <th>이미지</th>
                            <th>이름</th>
                            <th>상태</th>
                            <th>가격</th>
                        </tr>
                        <tr v-for="item in deck_list" :key="item.id">
                            <td width="80" class="pl-3">
                                <v-img
                                    @click="selected_item = item; dialog.image = true"
                                    style="cursor:pointer;"
                                    width="48"
                                    :src="'/upload/trade/deck/'+item.deck_image"
                                ></v-img>
                            </td>
                            <td width="76" style="cursor:pointer" @click="goToDetail(item.deck_id)">
                                <!-- <v-img width="48" src="@/assets/default.png"></v-img> -->
                                <v-img
                                    v-if="item.deck_nation=='en'" 
                                    width="48" 
                                    :src="'/upload/card_en_image/'+(item.main_pokemon_image==null? 'default.png':encodeURI(item.main_pokemon_image))"
                                ></v-img>
                                <v-img 
                                    v-if="item.deck_nation=='kr'"
                                    width="48" 
                                    :src="'/upload/card_kr_image/'+(item.main_pokemon_image==null? 'default.png':encodeURI(item.main_pokemon_image))"
                                ></v-img>
                                <v-img 
                                    v-if="item.deck_nation=='jp'" 
                                    width="48" 
                                    :src="'/upload/card_jp_image/'+(item.main_pokemon_image==null? 'default.png':encodeURI(item.main_pokemon_image))"
                                ></v-img>
                            </td>
                            <td style="cursor:pointer" @click="goToDetail(item.deck_id)">
                                <p class="mb-0">{{item.deck_name}}</p>
                            </td>
                            <td width="120" class="text-center">{{item.deck_state}}</td>
                            <td width="200" class="text-center">{{$toComma(item.deck_price)}}원</td>
                        </tr>
                    </table>
                </v-sheet>

                <v-sheet class="pa-2">
                    <table class="cardList">
                        <tr>
                            <th>이미지</th>
                            <th>이름</th>
                            <th>상태</th>
                            <th>가격</th>
                        </tr>
                        <tr v-for="item in card_list" :key="item.id">
                            <td width="76" style="cursor:pointer">
                                <v-img
                                    width="48" 
                                    :src="computed_image(item)"
                                ></v-img>
                            </td>
                            <td style="cursor:pointer; width:200px;">
                                <p class="mb-0" style="font-size:10px;">{{item.card_name}}</p>
                                <p class="mb-0 text-caption">
                                    <v-icon small style="margin-bottom:2px;" class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                    <font style="font-size:10px;" v-if="item.card_nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                    <font style="font-size:10px;" v-if="item.card_nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                    <font style="font-size:10px;" v-if="item.card_nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                    <font style="font-size:10px;" v-if="item.card_nation=='en' && item.printedTotal"> / {{item.printedTotal.replaceAll("\"", "")}}</font>
                                <font style="font-size:10px;" v-if="item.card_nation=='kr' && item.set_printedTotal_kr"> / {{item.set_printedTotal_kr}}</font>
                                <font style="font-size:10px;" v-if="item.card_nation=='jp' && item.set_printedTotal_jp"> / {{item.set_printedTotal_jp}}</font>
                                </p>
                                <p v-if="item.card_nation=='en' && item.set_en" class="ml-2px mb-0" style="font-size:10px;">{{item.set_en}}</p>
                                <p v-if="item.card_nation=='kr' && item.set_kr" class="ml-2px mb-0" style="font-size:10px;">{{item.set_kr}}</p>
                                <p v-if="item.card_nation=='jp' && item.set_jp" class="ml-2px mb-0" style="font-size:10px;">{{item.set_jp}}</p>
                            </td>
                            <td width="100" style="font-size:10px;">{{item.card_state}}</td>
                            <td width="120" style="font-size:10px;">{{$toComma(item.card_price)}}원</td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet
                    class="px-2 mt-2"
                >
                    <p class="mr-2 mb-1" style="font-size:13px;">
                        <span class="mr-2">거래방법</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{order_detail2.trade_way}}</span>

                        <span class="mr-2">총 수량</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{card_list.length}}개</span><br/>

                        <span class="mr-2">총 가격</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{$toComma(selectedSum)}}원</span>

                        <span class="mr-2">배송비</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{order_detail2.delivery_price}}원</span>
                    </p>

                    <p class="mr-2 mb-2" style="font-size:13px;">
                        <span class="mr-2">주소</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{order_detail2.seller_address}}</span><br/>

                        <span class="mr-2">전화번호</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{seller_phone}}</span>
                    </p>
                </v-sheet>
                <v-divider class="mx-2"></v-divider>
                <v-sheet class="mt-4 pb-6 px-2">
                    <p class="mr-6" style="font-size:13px;">
                        <span class="mr-2">구매자 배송지</span>
                        <span class="font-weight-bold" style="color:#615DFA;">{{order_detail.shipping_address}}</span><br/>

                        <span class="mr-2">구매자 전화번호</span>
                        <span class="font-weight-bold" style="color:#615DFA;">{{buyer_phone}}</span>
                    </p>
                    <div
                        style="font-size:16px; white-space:pre-line;"
                        v-html="order_detail.content"
                    ></div>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 리뷰쓰기 (dialog) -->
        <v-dialog v-model="dialog.review" width="600">
            <v-card>
                <p class="text-subtitle-1 font-weight-bold py-2 ma-0 text-center">리뷰쓰기</p>
                <v-divider></v-divider>
                <v-sheet class="pa-2">
                    <table class="order_table">
                        <tr>
                            <td>
                                평점
                            </td>
                            <td>
                                <v-rating
                                    v-model="review.rating"
                                    half-increments
                                    background-color="grey"
                                    color="blue"
                                ></v-rating>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                리뷰
                            </td>
                            <td>
                                <v-sheet min-height="120">
                                    <v-textarea
                                        v-model="review.content"
                                        placeholder="리뷰 내용을 작성해주세요"
                                        outlined
                                        dense hide-details
                                    ></v-textarea>
                                </v-sheet>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="d-flex justify-center pa-4">
                    <v-btn class="grey lighten-1 mx-2" dark width="40%" text large outlined @click="dialog.review=false">
                        취소하기
                    </v-btn>
                    <v-btn class="blue mx-2" dark width="40%" text large outlined @click="review_submit()">
                        작성하기
                    </v-btn>
                </v-sheet>
            </v-card>
        </v-dialog>

        <!-- 메세지 신고하기 (Dialog) -->
        <v-dialog v-model="dialog.reportMessage" width="800" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <!-- 제목 -->
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    메세지 신고하기
                </p>

                <!-- 신고 메세지 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 메세지</span>
                        <v-sheet class="text-truncate" style="width:440px;">
                            {{reportMessage.reported_content}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 대상자 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:95px;">신고 대상자</span>
                        {{reportMessage.writer}}
                    </div>
                </v-sheet>

                <!-- 신고 항목 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:230px;">신고 항목</span>
                        <v-select
                            style="max-width:220px;"
                            placeholder="신고 항목"
                            dense
                            hide-details
                            solo
                            flat
                            :items="select_list.report"
                            v-model="reportMessage.category"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 신고 내용 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">신고 내용</span>
                    <v-textarea
                        class="no_under"
                        rows="4"
                        hide-details
                        v-model="reportMessage.content"
                        placeholder="내용을 입력해주세요"
                    ></v-textarea>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-6 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="report_comment_submit()"
                    >
                        신고
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.reportMessage=false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.imageMessage"
            :width="300"
            content-class="rounded-15"
        >
            <v-sheet class="pa-4" style="z-index:1 !important;">
                <table class="table_style">   
                    <!-- 이미지 편집/미리보기 -->       
                    <tr>
                        <td class="pa-3">
                            <clipper-basic
                                :src="url"
                                ref="clipper"
                                class="my-clipper"
                                :init-width="100"
                                :init-height="100"
                            >
                                <div class="placeholder" slot="placeholder"></div>
                            </clipper-basic>
                        </td>
                    </tr>

                    <!-- 파일 입력 -->
                    <tr>
                        <td class="py-2 px-3">
                            <v-sheet
                                @drop.prevent="addDropFile"
                                @dragover.prevent
                            >
                                <v-file-input
                                    placeholder="카드 이미지를 선택해주세요"
                                    outlined
                                    dense
                                    show-size
                                    hide-details
                                    prepend-icon
                                    color="icu_purple"
                                    v-model="uploadImage"
                                    @change="onFileChange()"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="mr-1" color="icu_purple">mdi-image-filter-hdr</v-icon>
                                    </template>
                                </v-file-input>
                            </v-sheet>
                        </td>
                    </tr>

                    <!-- 제출 버튼 -->
                    <tr>
                        <td class="py-2 px-3">
                            <v-btn
                                @click="imageSubmit()"
                                depressed
                                dark
                                color="icu_purple"
                                width="100%"
                            >
                                이미지 보내기
                            </v-btn>
                        </td>
                    </tr>
                </table>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import PointModule from '@/components/PointModule.js'

export default {
    props: ["first_target", "channel_target"],

    data: () => ({
        target: "",
        message: "",
        list: [],

        order_detail:{
            trade_way: "",
            shipping_address: "",
            delivery_price: ""
        },

        order_detail2:{
            trade_way: "",
            delivery_price: ""
        },

        card_list:[],
        deck_list:[],
        selected_item: {},

        // 메시지 신고
        reportMessage: {
            reported_content: "",
            id: 0,
            writer: "",
            category: "",
            content: ""
        },

        // 메시지 신고 선택지
        select_list: {
            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        // dialog
        dialog: {
            order: false,
            review: false,
            reportMessage: false,
            imageMessage: false
        },

        review: {
            rating: 0,
            content: ""
        },

        seller_phone: "",
        buyer_phone: "",

        uploadImage: {},
        url: "",
    }),

    watch: {
        // 처음 입력된 대화상대에 따른 채널 내용 import
        first_target(){
            this.target = this.first_target
            this.load(this.first_target)
        },

        // 변경된 대화상대에 따른 채널 내용 import
        channel_target(){
            this.target = this.channel_target
            this.load(this.channel_target)
        },
    },

    computed:{
        selectedSum(){
            let sum = 0

            this.card_list.forEach(e => {
                sum +=  e.card_price
            })

            return Math.round(parseInt(sum))
        }
    },

    methods: {
        // 메세지 불러오기
        load(target){
            this.$http.post('/api/mypage/message/channel/list/detail', {
                params: {
                    me: this.$store.state.user_id,
                    other: target
                }
            }).then(res => {
                this.list = res.data
            })
        },

        // 요청한 내용 확인 (거래한 내용 확인)
        open_order(order_id){
            if(!order_id){
                return
            }
            this.card_list = []
            this.dialog.order = true
            this.$http.post('/api/mypage/message/order/detail', {
                params: {
                    order_id: order_id
                }
            }).then(res => {
                console.log(res)
                this.order_detail = res.data[0]
                this.$http.post('/api/mypage/message/order/detail/card_list', {
                    params: {
                        ids:res.data[0].trade_post_card_ids
                    }
                }).then(res2 => {
                    console.log(res2)
                    if(res2.data.length){
                        this.order_detail2 = res2.data[0]
                        this.card_list = res2.data

                        // 판매자 전화번호 가져오기
                        this.$http.post('/api/mypage/message/find/phone', {
                            params: {
                                user_id: this.order_detail.seller,
                                address: this.order_detail2.seller_address
                            }
                        }).then(res3 => {
                            if(res3.data.length){
                                this.seller_phone = res3.data[0].phone
                            }
                        })

                        // 구매자 전화번호 가져오기
                        this.$http.post('/api/mypage/message/find/phone', {
                            params: {
                                user_id: this.order_detail.buyer,
                                address: this.order_detail.shipping_address
                            }
                        }).then(res4 => {
                            if(res4.data.length){
                                this.buyer_phone = res4.data[0].phone
                            }
                        })
                    }
                })

                this.$http.post('/api/trade/post/read/import/deckList', {
                    params: {
                        post_id : this.order_detail.post_id
                    }
                }).then(res => {
                    this.deck_list = res.data
                })
            })
        },

        // 거래 수락하기
        accept_order(item){
            this.$http.post('/api/mypage/message/order/accept', {
                params: {
                    order_id:item.order_id
                }
            }).then(res => {
                this.$http.post('/api/mypage/message/order/accept/message_update', {
                    params: {
                        message_id:item.id
                    }
                }).then(res => {
                    // 채널 Reload
                    this.$http.post('/api/mypage/message/channel/list/detail', {
                        params: {
                            me: this.$store.state.user_id,
                            other: this.target
                        }
                    }).then(res => {
                        this.list = res.data
                        alert('거래가 수락되었습니다')
                    })

                    // item.order_id에서 post_id와 card의 id(array)가져오기
                    this.$http.post('/api/mypage/message/import/order', {
                        params: {
                            order_id: item.order_id
                        }
                    }).then((res2) => {
                        // 거래카드 상태변경
                        for(let id of res2.data[0].trade_post_card_ids.split(",")){
                            this.$http.post('/api/mypage/message/update/card/state', {
                                params: {
                                    post_id: res2.data[0].post_id,
                                    id: id
                                }
                            })
                        }
                    })

                    // 거래 수락했다고 상대에게 알림
                    this.$http.post('/api/mypage/message/order/accept/alarm_to_buyer', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "trade_accepted"
                        }
                    })
                })
            })
        },

        // 거래 취소하기
        cancel_order(item){
            this.$http.post('/api/mypage/message/order/cancel', {
                params: {
                    order_id:item.order_id
                }
            }).then(res => {
                this.$http.post('/api/mypage/message/order/cancel/message_update', {
                    params: {
                        message_id:item.id
                    }
                }).then(res => {
                    // 카드들 상태 "판매완료" => "등록됨"
                    this.$http.post('/api/mypage/message/order/detail', {
                        params: {
                            order_id:item.order_id
                        }
                    }).then(res => {
                        this.$http.post('/api/mypage/message/order/card_not_sold', {
                            params: {
                                ids:res.data[0].trade_post_card_ids
                            }
                        })
                    })

                    // 채널 Reload
                    this.$http.post('/api/mypage/message/channel/list/detail', {
                        params: {
                            me: this.$store.state.user_id,
                            other: this.target
                        }
                    }).then(res => {
                        this.list = res.data
                        alert('거래요청을 취소하였습니다')
                    })

                    // 거래 요청 취소했다고 상대에게 알림
                    this.$http.post('/api/mypage/message/order/cancel/alarm_to_buyer', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "trade_canceled"
                        }
                    })
                })
            })
        },

        // 거래 거절하기
        refuse_order(item){
            this.$http.post('/api/mypage/message/order/refuse', {
                params: {
                    order_id:item.order_id
                }
            }).then(res => {
                this.$http.post('/api/mypage/message/order/refuse/message_update', {
                    params: {
                        message_id:item.id
                    }
                }).then(res => {
                    // 채널 Reload
                    this.$http.post('/api/mypage/message/channel/list/detail', {
                        params: {
                            me: this.$store.state.user_id,
                            other: this.target
                        }
                    }).then(res => {
                        this.list = res.data
                        alert('거래를 거절하였습니다')
                    })

                    // 거래 거절했다고 판매자 자신에게 알림
                    this.$http.post('/api/mypage/message/order/cancel/alarm_to_buyer', {
                        params: {
                            user_id: this.$store.state.user_id,
                            sender: this.target,
                            type: "trade_refused"
                        }
                    })

                    // 거래 거절했다고 상대에게 알림
                    this.$http.post('/api/mypage/message/order/refuse/alarm_to_buyer', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "trade_refused"
                        }
                    })
                })
            })
        },

        // 거래 완료하기
        buyer_finish_trade(item){
            if(confirm("해당 거래를 완료하시겠습니까?")){
                this.$http.post('/api/mypage/message/order/buyer_finish_trade/order', {
                    params: {
                        order_id: item.order_id
                    }
                }).then(() => {
                    this.$http.post('/api/mypage/message/order/buyer_finish_trade/message', {
                        params: {
                            message_id: item.id
                        }
                    }).then(() => {
                        // 채널 Reload
                        this.$http.post('/api/mypage/message/channel/list/detail', {
                            params: {
                                me: this.$store.state.user_id,
                                other: this.target
                            }
                        }).then(res => {
                            this.list = res.data
                        })
                    })

                    // 거래 완료했다고 판매자에게 알림
                    this.$http.post('/api/mypage/message/order/buyer_finish_trade/alarm_to_seller', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "trade_finished"
                        }
                    })

                    // 거래 완료했다고 구매자에게 알림
                    this.$http.post('/api/mypage/message/order/buyer_finish_trade/alarm_to_seller', {
                        params: {
                            user_id: this.$store.state.user_id,
                            sender: this.target,
                            type: "trade_finished"
                        }
                    })

                    // 카드들 상태 "등록됨" => "판매완료"
                    this.$http.post('/api/mypage/message/order/detail', {
                        params: {
                            order_id:item.order_id
                        }
                    }).then(res => {
                        this.$http.post('/api/mypage/message/order/card_sold', {
                            params: {
                                ids:res.data[0].trade_post_card_ids
                            }
                        })
                    })
                })
            }
        },

        // 리뷰쓰기 열기
        open_review(item){
            this.review = {
                rating: 0,
                content: ""
            }
            this.selected_item = item
            this.dialog.review = true
        },

        // 리뷰쓰기 Submit
        review_submit(){
            this.$http.post('/api/mypage/message/order/review/submit', {
                params: {
                    order_id: this.selected_item.order_id,
                    seller: this.selected_item.reciever,
                    buyer: this.selected_item.sender,
                    rating: this.review.rating,
                    content: this.review.content
                }
            }).then(res => {
                // 포인트 적립
                PointModule.amountCheck('카드거래', '매너 점수 등록시').then((res) => {
                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                    PointModule.Record(this.$store.state.user_id, '카드거래', '매너 점수 등록시', this.selected_item.order_id, res.data[0].amount)
                })

                // 메세지 타입 update
                this.$http.post('/api/mypage/message/order/review/message/update', {
                    params: {
                        message_id:this.selected_item.id
                    }
                }).then(res => {
                    // 채널 Reload
                    this.$http.post('/api/mypage/message/channel/list/detail', {
                        params: {
                            me: this.$store.state.user_id,
                            other: this.target
                        }
                    }).then(res => {
                        this.list = res.data
                        alert("리뷰가 작성되었습니다.")
                        this.dialog.review = false
                    })

                    // 리뷰 작성했다고 상대에게 알림
                    this.$http.post('/api/mypage/message/order/accept/alarm_to_buyer', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "review_writed"
                        }
                    })
                })
            })
        },

        // 메세지 신고하기 열기
        report_message_open(item){
            this.dialog.reportMessage = true
            this.reportMessage.id = item.id
            this.reportMessage.writer = item.user_id
            this.reportMessage.reported_content = item.content
        },

        // 메세지 신고하기 제출
        report_comment_submit(){
            if(this.reportMessage.category == ""){
                alert("신고 항목을 선택해주세요")
            }else if(this.reportMessage.content == ""){
                alert("신고 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/user/reported/insert', {
                    params: {
                        reporter_user_id: this.$store.state.user_id,
                        reported_user_id: this.reportMessage.writer,
                        type: "message",
                        reported_id: this.reportMessage.id,
                        category: this.reportMessage.category,
                        content: this.reportMessage.content,
                        link: this.$route.fullPath+"&reported_comment_id="+this.reportMessage.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("신고가 접수되었습니다.")
                        this.dialog.reportMessage = false
                    }
                })
            }
        },

        // 메세지 입력
        submit(){
            if(!this.message.length){
                alert("메세지 내용을 입력해주세요")
            }else if(this.message.length > 1500){
                alert("메세지 내용은 1,500자 이내로 입력해주세요")
            }else{
                this.$http.post('/api/mypage/message/insert', {
                    params: {
                        sender: this.$store.state.user_id,
                        reciever: this.channel_target,
                        content: this.message
                    }
                }).then(res => {
                    this.load(this.target)
                    this.$emit("message")

                    this.message = ""

                    // 알림함에 추가
                    this.$http.post('/api/alarm/insert', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "message"
                        }
                    })
                })

                this.$nextTick(() => {
                    setTimeout(() => {
                        const container = this.$refs.drawContainer
                        container.scrollTop = container.scrollHeight
                    }, 50)  // 50ms 지연 후 실행
                })
            }
        },

        // 파일 드래그앤드롭
        addDropFile(e) { 
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },
        
        // 파일 선택시 이미지 표시
        onFileChange() {
            const file = this.uploadImage
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },

        // 이미지 업로드
        imageUpload: _.debounce(function() {
            // Crop 이미지 파일로 변환
            const canvas = this.$refs.clipper.clip() //call component's clip method
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
            var formData = new FormData()
            formData.append("image", file)

            // 이미지 업로드
            this.$http.post('/api/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    type: "message"
                }
            }).then((res) => {
                this.$http.post('/api/mypage/message/insert/image', {
                    params: {
                        sender: this.$store.state.user_id,
                        reciever: this.channel_target,
                        content: res.data
                    }
                }).then(res => {
                    this.dialog.imageMessage = false
                    this.load(this.target)
                    this.$emit("message")

                    this.message = ""

                    // 알림함에 추가
                    this.$http.post('/api/alarm/insert', {
                        params: {
                            user_id: this.target,
                            sender: this.$store.state.user_id,
                            type: "message"
                        }
                    })
                })

                this.$nextTick(() => {
                    setTimeout(() => {
                        const container = this.$refs.drawContainer
                        container.scrollTop = container.scrollHeight
                    }, 50)  // 50ms 지연 후 실행
                })
            })
        }, 500),

        // 이미지 입력
        imageSubmit(){
            if(!this.uploadImage.name){
                alert("보내실 이미지를 선택해주세요")
            }else{
                this.imageUpload()
            }
        },

        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },
    }
}
</script>
<style>
.order_table{
    border-collapse: collapse;
}

.order_table tr td{
    width:500px;
    padding:8px;
}

.order_table tr td:first-child{
    width:100px;
}

.cardList{
    width:100%;
}

.cardList tr td{
    padding:8px;
    border-bottom:1px solid #e0e0e0;
}

.cardList tr th{
    font-size:10pt;
    text-align:left;
    padding:8px;
    border-bottom:1px solid #e0e0e0;
}
</style>
<style scoped>
.table_style{
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ccc;
    padding:10px;
}

.my-clipper {
    width:100%;
}

.placeholder {
    width:245px;
    height:363px;
}
</style>