<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 마이페이지 기본 헤더 -->
            <DefaultHeader />

            <v-sheet class="pa-4 rounded-15">
                <v-sheet>
                    <p
                        class="text-center font-weight-bold py-4"
                        style="font-size:24px;"
                    >
                        배송지 관리
                    </p>

                    <v-data-table
                        class="ma-1"
                        :headers="headers"
                        :items="post_on_current_page"
                        no-data-text="배송지가 존재하지 않습니다."
                        hide-default-footer
                        style="border:1px solid #e0e0e0"
                    >
                        <template v-slot:item="{ item }">
                            <tr style="cursor:pointer">
                                <td width="100px">{{item.receiver}}</td>
                                <td width="110px">{{item.phone}}</td>
                                <td width="320px">{{item.address}}</td>
                                <td width="75px" class="pa-2">
                                    <v-btn v-if="item.selected=='기본'" small dark depressed class="rounded-10" color="#23D2E2">기본</v-btn>
                                    <v-btn v-if="item.selected!='기본'" small outlined class="rounded-10" color="#23D2E2" @click="update_address(item)">설정</v-btn>
                                </td>
                                <td width="75px" class="pa-2"><v-btn small dark depressed class="rounded-10" color="#F8468D" @click="delete_address(item)">삭제</v-btn></td>
                            </tr>
                        </template>
                    </v-data-table>

                    <p style="font-size:11px;" class="mt-2 mb-1 mr-2 text-right">
                        입력된 배송지와 연락처는 포인트몰 상품 배송과 카드거래에 사용됩니다.
                    </p>

                    <v-sheet class="d-flex justify-space-between px-1 py-4">

                        <!-- Justify 더미 -->
                        <div style="width:125px" />

                        <!-- 페이지 -->
                        <v-pagination
                            v-model="page"
                            :length="page_length"
                        ></v-pagination>

                        <!-- 배송지 추가하기 -->
                        <Create @reload="load()" />
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 등급 참고용 가이드 안내문구 -->
            <v-sheet class="px-4 py-2 mt-2 rounded-15 d-flex align-center justify-space-between">
                <p class="text-left my-0" style="font-size:14px !important; margin-top:3px !important;">
                    <v-icon color="#ADAFCA" class="mb-3px">
                        mdi-alert-circle-outline
                    </v-icon>
                    <font style="font-size:14px !important;">
                        최초 배송지와 연락처를 등록하시면 <span class="primary--text">포인트</span>가 지급됩니다.
                    </font>
                </p>

                <BannerView
                    v-if="$store.state.ads_banner.mypage"
                    type="mypage"
                    width="360"
                    height="46"
                    :round="'rounded-10'"
                />
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="py-3 pr-0 mx-4 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p style="font-size:16px; font-weight:bold; margin-bottom:0px;">마이페이지</p>
                </div>

                <v-spacer></v-spacer>
                <div
                    style="width:120px;"
                >
                    <!-- 배송지 추가하기 -->
                    <Create @reload="load()" />
                </div>
            </div>

            <v-data-table
                :headers="headers"
                :items="post_on_current_page"
                no-data-text="배송지가 존재하지 않습니다."
                hide-default-footer
                mobile-breakpoint="0"
            >
                <template v-slot:item="{ item }">
                    <tr style="cursor:pointer">
                        <td width="100px">{{item.receiver}}</td>
                        <td width="110px">{{item.phone}}</td>
                        <td width="320px">{{item.address}}</td>
                        <td width="75px" class="pa-2">
                            <v-btn v-if="item.selected=='기본'" small dark depressed class="rounded-10" color="#23D2E2">기본</v-btn>
                            <v-btn v-if="item.selected!='기본'" small outlined class="rounded-10" color="#23D2E2" @click="update_address(item)">설정</v-btn>
                        </td>
                        <td width="75px" class="pa-2"><v-btn small dark depressed class="rounded-10" color="#F8468D" @click="delete_address(item)">삭제</v-btn></td>
                    </tr>
                </template>
            </v-data-table>

            <p style="font-size:11px;" class="pt-4 pb-10 mb-0 mr-2 text-right white">
                입력된 배송지와 연락처는 포인트몰 상품 배송과 카드거래에 사용됩니다.
            </p>

            <v-sheet class="d-flex justify-center px-1 py-4">
                <!-- 페이지 -->
                <v-pagination
                    v-model="page"
                    :length="page_length"
                ></v-pagination>
            </v-sheet>

            <!-- 등급 참고용 가이드 안내문구 -->
            <v-sheet class="px-2 py-1 mt-2 mx-8 mb-16 text-center rounded-15">
                <p class="text-left my-2" style="font-size:10px !important;">
                    <v-icon color="#ADAFCA" class="mb-3px">
                        mdi-alert-circle-outline
                    </v-icon>
                    <font style="font-size:10px !important;">
                        최초 배송지와 연락처를 등록하시면 <span class="primary--text">포인트</span>가 지급됩니다.
                    </font>
                </p>
            </v-sheet>

            <BannerView
                v-if="$store.state.ads_banner.mypage"
                class="mt-3"
                type="mypage"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />
        </div>
    </div>
</template>
<script>
// 마이페이지 기본 헤더
import DefaultHeader from './components/DefaultHeader'

// 배송지 추가하기
import Create from "./components/AddressCreate.vue"

// 배너
import BannerView from '@/components/BannerView'

export default {
    components: {
        DefaultHeader,
        Create,
        BannerView
    },
    data: () => ({
        headers: [
            { text: '받으실 분' },
            { text: '연락처' },
            { text: '배송지' },
            { text: '기본' },
            { text: '삭제' }
        ],
        post_on_current_page: [],
        posting_list: [],
        page: 1,
        page_length: 1,
    }),
    
    mounted () {
        // 목록
        this.load()
    },

    watch: {
        // 페이지 넘기기
        page(val, preVal) {
            if (val !== preVal) {
                var start = (this.page-1)*6
                var end = (this.page*6)
                this.post_on_current_page = this.posting_list.slice(start,end)
            }
        }
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/mypage/address/list', {
                params: {
                    user_id:this.$store.state.user_id
                }
            }).then((res) => {
                this.posting_list = res.data.reverse()
                this.post_on_current_page = this.posting_list.slice(0, 6)
                this.page_length = Math.ceil(this.posting_list.length/6)

                // 1개일시 자동으로 
                if(this.posting_list.length == 1){
                    this.$http.post('/api/mypage/address/select', {
                        params: {
                            user_id: this.$store.state.user_id,
                            id: this.posting_list[0].id
                        }
                    })
                }
            })
        },

        // 기본 배송지 변경
        update_address(item){
            if(confirm("해당 배송지를 기본 배송지로 지정하겠습니까?")){
                this.$http.post('/api/mypage/address/select', {
                    params: {
                        user_id: this.$store.state.user_id,
                        id: item.id
                    }
                }).then(() => {
                    this.$router.go(this.$router.currentRoute)
                })
            }
        },

        // 배송지 삭제
        delete_address(item){
            if(confirm("정말 해당 배송지를 삭제하시겠습니까?")){
                this.$http.post('/api/mypage/address/delete', {
                    params: {
                        id: item.id
                    }
                }).then(() => {
                    this.$router.go(this.$router.currentRoute)
                })
            }
        }
    }
}
</script>
<style scoped>
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>