<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 마이페이지 기본 헤더 -->
            <DefaultHeader />

            <v-sheet class="pa-4 rounded-10">
                <!-- 프로필 상단 -->
                <Summary :user_id="$store.state.user_id" />

                <!-- 버튼 -->
                <MypageButtons />

                <!-- 일반 목록 -->
                <ListCommon :user_id="$store.state.user_id" />

                <!-- 제보 목록 -->
                <ListProvide :user_id="$store.state.user_id"/>

                <!-- 카드 입력 목록 -->
                <v-sheet
                    v-if="
                        $store.state.type=='운영진'
                        || $store.state.type=='매니저'
                        || $store.state.type=='관리자'
                        || $store.state.type=='일어알바'"
                >
                    <ListCard />
                </v-sheet>
            </v-sheet>
        </div>

        <!-- 모바일 -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 제목 -->
            <div
                class="d-flex justify-space-between align-center px-5 py-3 mobile"
            >
                <p
                    style="font-size:16px; font-weight:bold; cursor:pointer;"
                    class="mb-0"
                    @click="$router.push('/mypage/home')"
                >
                    마이페이지
                </p>

                <v-spacer></v-spacer>

                <!-- 포인트 -->
                <font
                    v-if="user.point"
                    class="font-weight-bold mr-3 mt-1px"
                    style="color:#23D2E2; font-size:12px; letter-spacing:-0.2px !important;"
                >
                    {{$toComma(user.point)}}P
                </font>

                <!-- 전적 -->
                <v-btn
                    width="32"
                    height="28"
                    x-small
                    style="font-size:10px;"
                    dark
                    color="primary"
                    class="rounded-10 mr-1"
                    depressed
                    @click="$router.push('/record?user_id='+$store.state.user_id)"
                >
                    전적
                </v-btn>

                <!-- 쪽지함 -->
                <v-btn
                    width="32"
                    height="28"
                    x-small
                    dark
                    color="primary"
                    class="rounded-10"
                    style="padding-top:2px;"
                    depressed
                    @click="$router.push('/mypage/message')"
                >
                    <v-icon size="14">mdi-message-processing-outline</v-icon>
                </v-btn>
            </div>

            <!-- 버튼 -->
            <div
                class="d-flex justify-space-between align-center mx-auto px-3 pb-2 mobile"
            >
                <v-btn
                    width="104"
                    height="34"
                    color="#615DFA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/mypage/home')"
                >
                    커뮤니티 활동
                </v-btn>
                <v-btn
                    width="104"
                    height="34"
                    color="#ADAFCA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/mypage/official_league')"
                >
                    대회 정보
                </v-btn>
                <v-btn
                    width="104"
                    height="34"
                    color="#ADAFCA"
                    dark
                    depressed
                    style="font-size:12px;"
                    class="rounded-10"
                    @click="$router.push('/record?user_id='+$store.state.user_id)"
                >
                    전적 관리
                </v-btn>
            </div>

            <v-sheet class="pa-4 rounded-10">
                <v-sheet
                    class="d-flex align-center pa-2 pt-0"
                >
                    <!-- 프로필 이미지 & 이미지 수정 -->
                    <v-sheet>
                        <!-- 뱃지 -->
                        <v-badge
                            color="#23D2E2"
                            offset-x="12"
                            offset-y="40"
                        >
                            <!-- 프로필 이미지 -->
                            <v-btn
                                @click="dialog.updateImage = true"
                                :style="`background-image:url('/upload/user_img/` + encodeURI(user.profile_image)+ `')`"
                                style="width:32px; height:32px; background-size: 32px 32px;"
                                elevation="0"
                                :fab="true"
                            ></v-btn>
                            
                            <!-- 뱃지 아이콘 -->
                            <template v-slot:badge>
                                <v-icon class="pb-2px">mdi-cog</v-icon>
                            </template>
                        </v-badge>

                        <!-- 프로필 이미지 수정 -->
                        <v-dialog v-model="dialog.updateImage" width="auto">
                            <UpdateProfileImage
                                @updated="load_profile(); dialog.updateImage = false"
                            />
                        </v-dialog>
                    </v-sheet>

                    <!-- 닉네임, 수정하기, 아이디, 등급, 유형 -->
                    <v-sheet class="ml-4 mb-1">
                        <!-- 닉네임, 수정하기 -->
                        <v-sheet>
                            <!-- 닉네임 -->
                            <font class="font-weight-bold" style="font-size:13px;">
                                {{user.nickname}}
                            </font>

                            <!-- 수정하기 -->
                            <router-link
                                v-if="user.user_id == $store.state.user_id"
                                to="/mypage/update"
                                style="text-decoration: none; color:#23D2E2;"
                            >
                                <font class="grey--text darken-2 ml-2" style="font-size:12px; ">수정</font>
                            </router-link>
                        </v-sheet>

                        <!-- 아이디, 등급, 유형 -->
                        <v-sheet height="16" class="d-flex">
                            <!-- 아이디 -->
                            <font class="grey--text text-body-2 darken-2">
                                {{user.user_id}}
                            </font>
                        </v-sheet>
                    </v-sheet>

                    <v-spacer></v-spacer>

                    <v-sheet
                        class="d-flex justify-end"
                    >
                        <!-- 등급 -->
                        <v-sheet color="transparent" width="14" height="14" style="margin-top:2px">
                            <v-img v-if="gradeIcon" :src="require(`@/assets/grade/${gradeIcon}.png`)" />
                        </v-sheet>

                        <!-- 유형 -->
                        <font class="ml-1 grey--text text-body-2 darken-2">
                            {{user.type}}
                        </font>
                    </v-sheet>
                </v-sheet>

                <v-sheet
                    v-if="$vuetify.breakpoint.mobile"
                    class="d-flex align-center justify-space-between pa-2 pb-0"
                >
                    <p
                        style="color:#1DA1F2; font-size:12px;"
                        class="mb-0"
                    >
                        {{$toComma(fromCreated)}}<span>일 활동중</span>
                    </p>
                    <div
                        style="width:1px; background:#eee; height:16px;"
                    ></div>
                    <p
                        style="color:#1DA1F2; font-size:12px;"
                        class="mb-0"
                    >
                        {{$toComma(postCount)}}<span>개 글작성</span>
                    </p>
                    <div
                        style="width:1px; background:#eee; height:16px;"
                    ></div>
                    <p
                        style="color:#1DA1F2; font-size:12px;"
                        class="mb-0"
                    >
                        {{$toComma(commentCount)}}<span>개 댓글담</span>
                    </p>
                </v-sheet>
            </v-sheet>

            <v-sheet class="rounded-10">
                <!-- 일반 목록 -->
                <ListCommon :user_id="$store.state.user_id" />

                <BannerView
                    v-if="$store.state.ads_banner.mypage"
                    class="my-3"
                    type="mypage"
                    width="100%"
                    :height="46/360*100+'vw'"
                    :round="'rounded-0'"
                />

                <!-- 제보 목록 -->
                <ListProvide :user_id="$store.state.user_id"/>

                <!-- 카드 입력 목록 -->
                <v-sheet v-if="$store.state.type=='운영진' || $store.state.type=='매니저' || $store.state.type=='관리자' || $store.state.type=='일어알바'">
                    <ListCard />
                </v-sheet>

                <BannerView
                    v-if="$store.state.ads_banner.mypage"
                    class="mt-3"
                    type="mypage"
                    width="100%"
                    :height="46/360*100+'vw'"
                    :round="'rounded-0'"
                />
            </v-sheet>
        </div>
    </div>
</template>
<script>
// 마이페이지 기본 헤더
import DefaultHeader from './components/DefaultHeader'

// 프로필 상단
import Summary from '@/views/mypage/components/Summary'

// 버튼
import MypageButtons from './components/Buttons'

// 일반 목록
import ListCommon from '@/views/mypage/components/Home/ListCommon'

// 제보 목록
import ListProvide from '@/views/mypage/components/Home/ListProvide'

// 카드 입력 목록
import ListCard from '@/views/mypage/components/Home/ListCard'

// 프로필 이미지 변경
import UpdateProfileImage from '@/views/mypage/components/UpdateProfileImage'

// 배너
import BannerView from '@/components/BannerView'

export default {
    components: {
        DefaultHeader,
        Summary,
        MypageButtons,
        ListCommon,
        ListProvide,
        ListCard,
        UpdateProfileImage,
        BannerView
    },

    data: () => ({
        dialog: {
            updateImage: false
        },

        fromCreated: 0,
        postCount: 0,
        commentCount: 0,

        user: {},
        gradeIcon: "",

        beta_tester_list: [],
    }),

    mounted(){
        // 유저 정보 불러오기
        this.load_profile()

        // 작성글 수 불러오기
        this.$http.post('/api/mypage/list/post', {
            params: {
                user_id: this.$store.state.user_id,
            }
        }).then((res) => {
            this.postCount = res.data.length
        })

        // 댓글 수 불러오기
        this.$http.post('/api/mypage/list/comment', {
            params: {
                user_id: this.$store.state.user_id,
            }
        }).then((res) => {
            this.commentCount = res.data.length
        })

        // # 베타테스터 목록 불러오기
        this.load_beta_tester_list()
    },

    methods: {
        // # 베타테스터 목록 불러오기
        load_beta_tester_list(){
            this.$http.post("/api/beta_tester/select")
            .then((res) => {
                this.beta_tester_list = res.data.map(e => e.user_id)
            })
        },

        // 회원 정보 불러오기
        load_profile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                this.user = res.data[0]

                // 활동중 일 수 계산
                let today = new Date(Date.now())
                let created = new Date(this.user.created)
                let sum = today - created
                let result = Math.ceil(sum/(24*3600*1000))
                this.fromCreated = result;

                // 유저 아이콘
                if (this.user.type == '사용자') {
                    if (this.user.grade == 0) {
                        this.gradeIcon = 'new'
                    } else {
                        this.gradeIcon = this.user.grade
                    }
                } else if(this.user.type == '밴유저') {
                    this.gradeIcon =  'banned'
                } else if (this.user.type == '관리자') {
                    this.gradeIcon = 'admin'
                } else if (this.user.type == '매니저') {
                    this.gradeIcon = 'manager'
                } else if (this.user.type == '운영진') {
                    this.gradeIcon = 'board_manager'
                }
            })
        },

        // 포인트 내역으로 이동
        linkToPoint() {
            this.$router.push('/mypage/point')
        }
    }
}
</script>