<template>
    <v-dialog
        v-model="dialog"
        :width="$vuetify.breakpoint.mdAndUp? 530 : '100%'"
        content-class="rounded-15"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :width="$vuetify.breakpoint.mdAndUp? 125 : '100%'"
                height="40"
                dark
                depressed
                class="rounded-10"
                style="font-size:12px;"
                color="#615DFA"
                v-bind="attrs"
                v-on="on"
            >
                배송지 추가하기
            </v-btn>
        </template>
        <v-sheet
            class="pa-6"
        >
            <v-sheet class="d-flex align-center">
                <v-icon>mdi-map-marker-outline</v-icon>
                <p class="text-h6 ma-0 ml-1">배송지 추가</p>
            </v-sheet>
            <v-divider class="my-2 mx-1"></v-divider>
            <v-simple-table id="address_add">
                <tr>
                    <td>
                        <v-text-field
                            color="blue"
                            outlined 
                            dense 
                            hide-details
                            placeholder="받으실 분"
                            v-model="receiver"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <v-text-field
                            color="blue" 
                            outlined 
                            dense 
                            hide-details
                            placeholder="전화번호"
                            v-model="phone"
                            type="number"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <v-text-field
                            color="blue" 
                            outlined 
                            dense 
                            hide-details
                            readonly
                            placeholder="우편 번호"
                            v-model="zip"
                        ></v-text-field>
                    </td> 
                    <td>
                        <v-btn block depressed style="border:1px solid #ccc;" @click="showApi">주소검색</v-btn>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <v-text-field
                            color="blue" 
                            outlined 
                            dense 
                            hide-details
                            readonly
                            placeholder="기본 주소"
                            v-model="addr1"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <v-text-field
                            color="blue" 
                            outlined 
                            dense 
                            hide-details
                            placeholder="상세 주소"
                            v-model="addr2"
                        ></v-text-field>
                    </td>
                </tr>
            </v-simple-table>
            <v-divider class="my-2 mx-1"></v-divider>

            <!-- Pc -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
                class="d-flex align-center px-1 mt-4"
            >
                <v-btn
                    block
                    class="blue rounded-lg"
                    dark
                    large
                    depressed
                    @click="submit()"
                >
                    배송지 추가하기
                </v-btn>

                <v-checkbox
                    class="ml-3 mt-0 mb-1"
                    v-model="agree"
                    hide-details
                    label="등록한 배송지 정보는 거래 신청자와 이벤트상품 발송자에게 전달 됩니다. 동의하지 않으시면 배송지 추가를 하실 수 없습니다."
                ></v-checkbox>
            </div>

            <!-- Mobile -->
            <div
                v-if="$vuetify.breakpoint.mobile"
                class="px-1 mt-2"
            >
                <v-checkbox
                    class="mb-4"
                    v-model="agree"
                    hide-details
                    label="등록한 배송지 정보는 거래 신청자와 이벤트상품 발송자에게 전달 됩니다. 동의하지 않으시면 배송지 추가를 하실 수 없습니다."
                ></v-checkbox>

                <v-btn
                    block
                    class="blue rounded-lg"
                    dark
                    large
                    depressed
                    @click="submit()"
                >
                    배송지 추가하기
                </v-btn>
            </div>
        </v-sheet>
    </v-dialog>
</template>
<script>
export default {
    data: () => ({
        dialog: false,

        zip: '', 
        addr1: '', 
        addr2: '',
        address: "",
        receiver: "",
        phone: "",

        agree: false
    }),

    methods: {
        showApi() { 
            new window.daum.Postcode({ 
                oncomplete: (data) => { 
                // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분. 
                // 도로명 주소의 노출 규칙에 따라 주소를 조합한다. 
                // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다. 
                let fullRoadAddr = data.roadAddress; 
                // 도로명 주소 변수 
                let extraRoadAddr = ''; 
                // 도로명 조합형 주소 변수 
                // 법정동명이 있을 경우 추가한다. (법정리는 제외) 
                // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다. 
                if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){ extraRoadAddr += data.bname; } 
                // 건물명이 있고, 공동주택일 경우 추가한다. 
                if(data.buildingName !== '' && data.apartment === 'Y'){ extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName); } 
                // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다. 
                if(extraRoadAddr !== ''){ extraRoadAddr = ' (' + extraRoadAddr + ')'; } 
                // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다. 
                if(fullRoadAddr !== ''){ fullRoadAddr += extraRoadAddr; } 
                // 우편번호와 주소 정보를 해당 필드에 넣는다. 
                this.zip = data.zonecode; 
                //5자리 새우편번호 사용 
                this.addr1 = fullRoadAddr; 
            }
            // }).embed(this.$refs.embed) 
            }).open()
        },

        submit(){
            if(!this.agree){
                alert("동의항목에 체크하셔야 추가하실 수 있습니다.")
                return
            }

            if(!this.receiver){
                alert("받으실 분을 입력해주세요")
            }else if(!this.phone){
                alert("전화번호를 입력해주세요")
            }else if(!this.zip){
                alert("주소를 검색하여 입력해주세요")
            }else{
                // 전체 주소
                if(this.addr2!=""){
                    this.address="("+this.zip+") "+this.addr1+" "+this.addr2;
                }else{
                    this.address="("+this.zip+") "+this.addr1;
                }

                // 첫 배송지 입력인지 확인
                this.$http.post('/api/mypage/address/isFirst', {
                    params: {
                        user_id:this.$store.state.user_id
                    }
                }).then((res) => {
                    if(!res.data.length){
                        // 포인트 지급
                        this.$http.post('/api/mypage/address/point/plus', {
                            params: {
                                point: 50,
                                user_id: this.$store.state.user_id
                            }
                        })

                        // 포인트 내역 기록
                        this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                            params: {
                                user_id: this.$store.state.user_id,
                                title: "첫 배송지 등록을 축하합니다.",
                                menu: '배송지 관리',
                                activity: '첫 입력',
                                pay_point: 50,
                            }
                        }).then((res) => {
                            if(!res.data.affectedRows){
                                alert("포인트 지급 도중 오류가 발생하였습니다.")
                            }else{
                                alert("첫 배송지 등록으로 50포인트가 지급되었습니다.")
                            }
                        })
                    }
                })

                // 배송지 입력하기
                this.$http.post('/api/mypage/address/create', {
                    params: {
                        user_id: this.$store.state.user_id,
                        receiver: this.receiver,
                        phone: this.phone,
                        zip: this.zip,
                        addr1: this.addr1,
                        addr2: this.addr2,
                        address: this.address,
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("배송지 입력도중 오류가 발생하였습니다.")
                    }else{
                        // 입력칸 초기화
                        this.zip=""
                        this.addr1=""
                        this.addr2=""
                        this.address=""

                        // 얼럿, load, ekerl
                        alert("배송지가 추가되었습니다.")
                        this.$emit("reload")
                        this.dialog = false
                    }
                })
            }
        }
    }
}
</script>
<style>
#address_add{
    padding:10px;
}

#address_add tr td{
    padding:5px;
}
</style>
<style scoped>
::v-deep .v-input .v-label {
    color: #f95596;
    font-size:10px;
    line-height:14px;
}
</style>