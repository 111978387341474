<template>
    <v-sheet class="mt-4">
        <v-divider></v-divider>
        <v-tabs
            v-model="tabs"
            grow
            color="#23D2E2"
            style="border-bottom:1px solid #e0e0e0;"
        >
            <v-tab v-for="n in menu" :key="n" style="width:100px;">
                <span class="text-caption text-md-body-2 font-weight-bold">{{ n }}</span>
            </v-tab>
        </v-tabs>
        <v-sheet :class="$vuetify.breakpoint.mobile? '':'px-2'">
            <v-tabs-items v-model="tabs">
                <v-tab-item>
                    <CardListKR />
                </v-tab-item>
                <v-tab-item>
                    <CardListJP />
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>
    </v-sheet>
</template>
<script>
// 입력한 카드목록
import CardListKR from '@/views/mypage/components/Home/ListCard/1_kr'
import CardListJP from '@/views/mypage/components/Home/ListCard/2_jp'

export default {
    components: {
        CardListKR,
        CardListJP
    },

    data: () => ({
        tabs: null,
        menu: [
            '내가 입력한 카드목록 (한글)',
            '내가 입력한 카드목록 (일본)'
        ]
    })
}
</script>