<template>
    <div>
        <v-sheet v-if="channel_list.length">
            <v-sheet v-for="(item, index) in channel_list" :key="index">
                <v-sheet
                    class="d-flex align-start pl-3 pr-2 my-2 py-3 rounded-10"
                    outlined
                    style="cursor:pointer"
                    @click="select_channel(item.user_id)"
                >
                    <Avatar v-if="item.user_id" :user="item.user_id" />
                    <v-sheet width="100%" class="pl-3 pr-2">
                        <v-sheet class="d-flex align-center">
                            <p
                                class="mb-0 font-weight-bold"
                                style="font-size:15px;"
                            >
                                {{item.nickname}}
                            </p>
                            <v-spacer></v-spacer>
                            <p class="mb-0" style="font-size:11px; color:#B3B3C2;">{{new Date(item.created).toLocaleDateString()}}</p>
                        </v-sheet>
                        <v-sheet class="custom-truncate text-caption mt-1">
                            {{item.content}}
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        <v-sheet v-if="!channel_list.length">
            <v-sheet class="d-flex justify-center my-10">
                <v-sheet>
                    <v-icon color="blue" large class="d-flex justify-center mb-4">mdi-cancel</v-icon>
                    <p class="text-caption font-weight-bold">아직 쪽지내역이 없습니다</p>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Avatar from "@/components/Avatar"

export default {
    props: ["reload"],

    components: {
        Avatar
    },

    data: () => ({
        channel_list: [],
    }),

    async mounted(){
        await this.channelList()

        if(this.channel_list.length){
            this.$emit("first_channel", this.channel_list[0].user_id)
        }else{
            this.$emit("first_channel")
        }
    },

    watch: {
        reload(){
            this.channelList()
        }
    },

    methods: {
        // 채널 목록
        async channelList(){
            // 내가 보낸 목록
            let me_send_list = []
            await this.$http.post('/api/mypage/message/channel/list/me/send', {
                params: {
                    user_id : this.$store.state.user_id
                }
            }).then(res => {
                me_send_list = res.data.map(e => e.reciever)
            })

            // 내가 받은 목록
            let me_receive_list = []
            await this.$http.post('/api/mypage/message/channel/list/me/recieve', {
                params: {
                    user_id : this.$store.state.user_id
                }
            }).then(res => {
                me_receive_list = res.data.map(e => e.sender)
            })

            // 합산
            let sum = 
            [
                ...new Set(
                    [
                        ...me_send_list,
                        ...me_receive_list
                    ]
                )
            ]

            // 채널 목록 표시
            this.channel_list = []
            for(let i=0; i<sum.length; i++){
                await this.$http.post('/api/mypage/message/channel/list/detailForList', {
                    params: {
                        me: this.$store.state.user_id,
                        other: sum[i]
                    }
                }).then(res => {
                    if(res.data.length){
                        this.channel_list.push(res.data[0])
                    }
                })
            }

            // 날짜 순 정렬
            this.channel_list.sort((a,b) => {
                return new Date(b.created) - new Date(a.created)
            })
        },

        // 선택한 채널
        select_channel(user_id){
            this.$emit("channel_selected", user_id)
        }
    }
}
</script>
<style>
.custom-truncate{
    display: -webkit-box;
    max-width: 230px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>