<template>
    <v-data-table
        :headers="headers"
        :items="list"
        :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
        no-data-text="아직 입력한 카드내역이 없습니다."
        mobile-breakpoint="0"
    >
        <template v-slot:item="{ item }">
            <tr @click="goToCardDetail(item)" style="cursor:pointer;">
                <td class="pa-2">
                    <v-sheet width="50">
                        <v-img contain :src="item.image!=null? '/upload/card_jp_image/'+encodeURI(item.image):'/upload/card_jp_image/default.png'"></v-img>
                    </v-sheet>
                </td>
                <td><font class="d-block text-truncate" style="width:100px;">{{item.id}}</font></td>
                <td><font class="d-block text-truncate" style="width:100px;">{{item.name}}</font></td>
                <td><font class="d-block text-truncate" style="width:100px;">{{item.rarity}}</font></td>
                <td><font class="d-block text-truncate" style="width:100px;">{{item.set_name}}</font></td>
                <td><font class="d-block text-truncate" style="width:80px;">{{new Date(item.last_modified_datetime).toLocaleDateString()}}</font></td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: 'IMG', value: 'image'},
            {text: '카드 고유ID', value: 'id'},
            {text: '카드명', value: 'name'},
            {text: '희귀도', value: 'rarity'},
            {text: '제품명', value: 'set_name'},
            {text: '입력시간', value: 'last_modified_datetime'}
        ],

        list: []
    }),

    mounted(){
        this.$http.post('/api/mypage/list/card/jp', {
            params: {
                user_id : this.$store.state.user_id
            }
        }).then((res) => {
            this.list=res.data
        })
    },


    methods: {
        goToCardDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        }
    }
}
</script>