<template>
    <v-data-table
        :headers="headers"
        :items="list"
        no-data-text="포인트 내역이 존재하지 않습니다."
        :footer-props="{'items-per-page-text':'페이지당 포인트 내역 수'}"
        mobile-breakpoint="0"
    >
        <template v-slot:item="{ item }">
            <tr>
                <td style="width:130px">{{item.menu}}</td>
                <td style="width:160px">{{item.activity}}</td>
                <td style="width:250px"><p class="text-truncate ma-0" style="width:250px;">{{item.title}}</p></td>
                <td style="width:100px">{{item.amount}}</td>
                <td style="width:150px">{{new Date(item.created).toLocaleDateString()}}</td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: ['user_id'],

    data: () => ({
        headers: [
            { text: '메뉴', value: 'menu', sortable: false },
            { text: '활동', value: 'activity', sortable: false },
            { text: '제목', value: 'title', sortable: false },
            { text: '포인트', value: 'amount', sortable: false },
            { text: '일자', value: 'created', sortable: false }
        ],
        list: []
    }),

    mounted() {
        this.load_list()
    },

    watch: {
        user_id: {
            deep:true,
            handler(newData) {
                this.load_list()
            }
        }
    },

    methods: {
        load_list(){
            this.$http.post('/api/mypage/list/point', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>