<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="pa-4 pr-0 d-flex align-center justify-space-between">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/message')"
                >
                    <p style="font-size:26px; font-weight:bold; margin-bottom:0px;">쪽지함</p>
                    <p style="font-size:11px; margin-top:-4px;" class="mb-0">포켓몬카드 DB/커뮤니티 너정다</p>
                </div>

                <BannerView
                    v-if="$store.state.ads_banner.mypage"
                    type="mypage"
                    width="360"
                    height="46"
                    :round="'rounded-10'"
                />
            </div>

            <!-- 본문 -->
            <div class="d-flex justify-space-between">
                <!-- 목록 -->
                <v-sheet
                    class="px-5 py-6 rounded-10"
                    width="331"
                >
                    <!-- 검색 -->
                    <v-sheet class="d-flex mb-4">
                        <!-- 입력란 -->
                        <v-autocomplete
                            class="rounded-10"
                            height="45"
                            placeholder="검색"
                            outlined
                            dense
                            hide-details
                            v-model="keyword"
                            :items="nickname_list"
                            @keyup.enter="search()"
                        >
                            <template v-slot:append>
                                <v-icon size="26" class="mt-2px" color="#424361">mdi-magnify</v-icon>
                            </template>
                        </v-autocomplete>

                        <!-- 버튼 -->
                        <v-btn
                            class="rounded-10 ml-2"
                            width="60"
                            height="45"
                            depressed
                            dark
                            color="primary"
                            @click="search()"
                        >
                            검색
                        </v-btn>
                    </v-sheet>
                    
                    <!-- 목록 -->
                    <ChannelList 
                        @channel_selected="channel_selected" 
                        @first_channel="first_channel" 
                        :reload="componentKey" 
                    />
                </v-sheet>

                <!-- 채널 본문 -->
                <v-sheet class="pa-2 rounded-10" width="495">
                    <!-- 상대 프로필 -->
                    <TargetProfile 
                        :channel_target="channel_target" 
                        :first_target="first_target"
                    />
                    
                    <!-- 채널 -->
                    <Channel 
                        @message="message"
                        :channel_target="channel_target"
                        :first_target="first_target"
                    />
                </v-sheet>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/message')"
                >
                    <p style="font-size:16px; font-weight:bold; margin-bottom:0px;">쪽지함</p>
                </div>
                <v-spacer></v-spacer>

                <v-btn
                    width="32"
                    height="28"
                    class="rounded-10 mr-1"
                    color="primary"
                    depressed
                    x-small
                    @click="channel_switch = !channel_switch"
                >
                    <v-icon small>mdi-magnify</v-icon>
                </v-btn>
                <v-btn
                    width="32"
                    height="28"
                    class="rounded-10"
                    color="primary"
                    depressed
                    x-small
                >
                    <v-icon small>mdi-message-outline</v-icon>
                </v-btn>
            </div>

            <v-sheet class="pa-2 pt-3">
                <!-- 검색 -->
                <v-sheet
                    v-if="channel_switch"
                    class="d-flex mb-2"
                >
                    <!-- 입력란 -->
                    <v-autocomplete
                        class="rounded-10"
                        height="45"
                        placeholder="검색"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        :items="nickname_list"
                        @keyup.enter="search()"
                    >
                        <template v-slot:append>
                            <v-icon size="26" class="mt-2px" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-autocomplete>

                    <!-- 버튼 -->
                    <v-btn
                        class="rounded-10 ml-2"
                        width="60"
                        height="45"
                        depressed
                        dark
                        color="primary"
                        @click="search()"
                    >
                        검색
                    </v-btn>
                </v-sheet>
                    
                <ChannelList 
                    v-show="channel_switch"
                    @channel_selected="channel_selected" 
                    @first_channel="first_channel" 
                    :reload="componentKey" 
                />

                <!-- 상대 프로필 -->
                <TargetProfile
                    v-show="!channel_switch"
                    :channel_target="channel_target" 
                    :first_target="first_target"
                />
                
                <!-- 채널 -->
                <Channel 
                    v-show="!channel_switch"
                    @message="message"
                    :channel_target="channel_target"
                    :first_target="first_target"
                />
            </v-sheet>
        </div>
    </div>
</template>
<script>
import ChannelList from './components/Message/ChannelList'
import TargetProfile from './components/Message/TargetProfile'
import Channel from './components/Message/Channel'
import BannerView from '@/components/BannerView'

export default {
    components: {
        ChannelList,
        TargetProfile,
        Channel,
        BannerView
    },

    data: () => ({
        keyword: "",
        channel_target: "",
        first_target: "",
        componentKey: 0,

        channel_switch: false,

        // 닉네임 목록
        nickname_list: []
    }),

    mounted(){
        // 닉네임 목록
        this.$http.post("/api/mypage/message/nicknameList")
        .then((res) => {
            this.nickname_list = res.data.map(e => e.nickname)
        })
    },
    
    methods: {
        // 첫 입장시 채널 선택
        first_channel(target){
            if(this.$route.query.to){
                // 쪽지 보내기로 들어왔을때
                this.$http.post('/api/mypage/message/target/profile/nickname', {
                    params: {
                        nickname: this.$route.query.to
                    }
                }).then(res => {
                    this.first_target = res.data[0].user_id
                    this.$http.post('/api/mypage/message/channel/list/detail', {
                        params: {
                            me: this.$store.state.user_id,
                            other: this.first_target
                        }
                    }).then(res => {
                        this.channel_target = this.first_target
                    })
                })
            }else{
                // 그냥 들어왔을때
                this.first_target = target
                this.$http.post('/api/mypage/message/channel/list/detail', {
                    params: {
                        me: this.$store.state.user_id,
                        other: target
                    }
                }).then(res => {
                    this.channel_target = target
                })
            }
        },

        // 채널 선택
        channel_selected(user_id){
            this.$http.post('/api/mypage/message/channel/list/detail', {
                params: {
                    me: this.$store.state.user_id,
                    other: user_id
                }
            }).then(res => {
                this.channel_target = user_id

                // Mobile
                this.channel_switch = false
            })
        },

        // 메세지 입력 시 ChannelList Reload
        message(){
            this.componentKey += 1
        },

        // 검색
        search(){
            this.$http.post("/api/mypage/message/search", {
                params: {
                    nickname: this.keyword
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("해당 닉네임의 유저가 없습니다.")
                }else{
                    if(this.$store.state.user_id == res.data[0].user_id){
                        alert("자기 자신에게 메세지를 보낼 수는 없습니다.")
                    }else{
                        this.channel_target = res.data[0].user_id
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:6px;
    font-size: 15px;
    font-weight: bold;
}
</style>