<template>
    <v-data-table
        :headers="headers"
        :items="list"
        no-data-text="정보 입력/수정 내역이 존재하지 않습니다."
        :footer-props="{
            'items-per-page-options': [10, -1],
            'items-per-page-text':'페이지당 이미지 제보 수'
        }"
        mobile-breakpoint="0"
    >
        <template v-slot:item="{ item }">
            <tr
                style="cursor:pointer;"
                @click="$router.push('/card/detail?id='+item.card_provide_id)"
            >
                <td style="width:142px">{{item.card_provide_id}}</td>
                <td style="width:100px">{{item.card_nation}}</td>
                <td style="width:470px">{{item.reason}}</td>
                <td style="width:130px">{{item.status}}</td>
                <td style="width:140px">{{new Date(item.created).toLocaleDateString()}}</td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: ['user_id'],

    data: () => ({
        headers: [
            { text: '카드 ID', value: 'card_provide_id' },
            { text: '국가', value: 'card_nation' },
            { text: '반려사유', value: 'reason' },
            { text: '상태', value: 'status' },
            { text: '제보일자', value: 'created' }
        ],
        list: []
    }),

    mounted() {
        // 목록
        this.load()
    },

    watch: {
        user_id: {
            deep:true,
            handler(newData) {
                this.load()
            }
        }
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/mypage/list/provide/info', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                console.log(res)
                this.list = res.data
            })
        }
    }
}
</script>