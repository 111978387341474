<template>
    <div>
        <!-- 마이페이지 기본 헤더 -->
        <DefaultHeader />

        <!-- 본문 -->
        <v-sheet class="rounded-10 py-10">
            <!-- 본문 제목 -->
            <p 
                class="font-weight-bold text-center"
                style="font-size:20px; color:#424361"
            >
                비밀번호 변경
            </p>

            <v-sheet width="300" class="mx-auto my-16">
                <!-- 기존 비밀번호 -->
                <v-text-field
                    v-model="origin_password"
                    type="password"
                    color="#615DFA"
                    class="mb-2 rounded-15"
                    label="기존 비밀번호"
                    persistent-placeholder
                    outlined
                    @change="$v.origin_password.$touch()"
                    @blur="$v.origin_password.$touch()"
                    :error-messages="origin_password_Errors"
                ></v-text-field>

                <!-- 변경할 비밀번호 -->
                <v-text-field
                    v-model="change_password"
                    type="password"
                    color="#615DFA"
                    class="mb-2 rounded-15"
                    label="변경할 비밀번호"
                    persistent-placeholder
                    outlined
                    @change="$v.change_password.$touch()"
                    @blur="$v.change_password.$touch()"
                    :error-messages="change_password_Errors"
                ></v-text-field>

                <!-- 변경할 비밀번호 확인 -->
                <v-text-field
                    v-model="change_check_password"
                    type="password"
                    color="#615DFA"
                    class="mb-4 rounded-15"
                    label="변경할 비밀번호 확인"
                    persistent-placeholder
                    outlined
                    @change="$v.change_check_password.$touch()"
                    @blur="$v.change_check_password.$touch()"
                    :error-messages="change_check_password_Errors"
                ></v-text-field>

                <!-- Submit 버튼 -->
                <v-sheet class="d-flex justify-center mb-10">
                    <v-btn
                        width="173"
                        height="56"
                        class="mx-2 rounded-10"
                        style="font-size:16px;"
                        dark
                        depressed
                        color="#615DFA"
                        @click="submit()"
                    >
                        비밀번호 변경
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
// 마이페이지 기본 헤더
import DefaultHeader from './components/DefaultHeader'

import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        DefaultHeader
    },

    data: () => ({
        origin_password:"",
        change_password:"",
        change_check_password:""
    }),

    mixins: [validationMixin],

    validations: {
        origin_password: {
            required,
            minLength: minLength(6)
        },
        change_password: {
            required,
            minLength: minLength(6)
        },
        change_check_password: {
            required,
            sameAsPassword: sameAs('change_password')
        }
    },

    computed: {
        // 기존 비밀번호 Error 문구
        origin_password_Errors () {
            const errors = []
            if (!this.$v.origin_password.$dirty) return errors
            !this.$v.origin_password.required && errors.push('기존 비밀번호를 입력해주세요')
            !this.$v.origin_password.minLength && errors.push('아이디는 최소 6자 이상이여야합니다')
            return errors
        },

        // 비밀번호 Error 문구
        change_password_Errors () {
            const errors = []
            if (!this.$v.change_password.$dirty) return errors
            !this.$v.change_password.required && errors.push('변경할 비밀번호를 입력해주세요')
            !this.$v.change_password.minLength && errors.push('비밀번호는 최소 6자 이상이여야합니다')
            return errors
        },

        // 비밀번호 확인 Error 문구
        change_check_password_Errors () {
            const errors = []
            if (!this.$v.change_check_password.$dirty) return errors
            !this.$v.change_check_password.required && errors.push('변경할 비밀번호 확인을 입력해주세요')
            !this.$v.change_check_password.sameAsPassword && errors.push('변경할 비밀번호 확인이 같지 않습니다')
            return errors
        },
    },

    methods: {
        submit: _.debounce(function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                if(this.change_password.length < 6)
                {
                    alert("비밀번호는 6자 이상이여야 합니다.")
                    return false
                }
                else if(this.change_password != this.change_check_password)
                {
                    alert("변경할 비밀번호 확인이 같지 않습니다.")
                    return false
                }else
                {
                    // 임시 비밀번호 확인
                    this.$http.post('/api/auth/login/temp_password/check', {
                        params: {
                            user_id: this.$store.state.user_id,
                            password: this.origin_password
                        }
                    }).then((res) => {
                        // 임시 비밀번호가 맞을시
                        if(res.data.length)
                        {
                            // 비밀번호 변경
                            this.$http.post('/api/user/update/password', {
                                params: {
                                    change_password: this.change_password,
                                    user_id: this.$store.state.user_id,
                                }
                            }).then((res) => {
                                if(res.data.affectedRows){
                                    alert("비밀번호가 성공적으로 변경되었습니다.")
                                    return this.$router.push('/mypage/home')
                                }
                                else{
                                    alert("비밀번호 변경에 실패하였습니다. 고객센터에 문의바랍니다.")
                                    return false
                                }
                            })
                        }
                        // 임시 비밀번호가 맞지 않을시
                        else
                        {
                            // 기존 비밀번호 확인
                            this.$http.post('/api/user/select/check', {
                                params: {
                                    user_id: this.$store.state.user_id,
                                    password: this.origin_password
                                }
                            }).then((res) => {
                                // 기존 비밀번호가 맞을시
                                if(res.data.length)
                                {
                                    // 비밀번호 변경
                                    this.$http.post('/api/user/update/password', {
                                        params: {
                                            change_password: this.change_password,
                                            user_id: this.$store.state.user_id,
                                        }
                                    }).then((res) => {
                                        if(res.data.affectedRows){
                                            alert("비밀번호가 성공적으로 변경되었습니다.")
                                            return this.$router.push('/mypage/home')
                                        }
                                        else{
                                            alert("비밀번호 변경에 실패하였습니다. 고객센터에 문의바랍니다.")
                                            return false
                                        }
                                    })
                                }
                                // 기존 비밀번호가 맞지 않을시
                                else
                                {
                                    alert("기존 비밀번호 값이 올바르지 않습니다.")
                                    return false
                                }
                            })  
                        }
                    })    
                }
            }
        }, 500)
    }
}
</script>
<style scoped>
#change_pw_table tr td:last-child{
    background:white;
}
</style>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:16px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}
</style>