<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p style="font-size:26px; font-weight:bold; margin-bottom:4px;">알림함</p>
                    <p style="font-size:11px;" class="mb-0">포켓몬카드 DB/커뮤니티 너정다</p>
                </div>
                <v-spacer></v-spacer>

                <!-- 공개유무 switch -->
                <v-switch
                    class="pt-2 font-weight-bold mr-4"
                    v-model="email_alarm_agree"
                    inset
                    :label="email_alarm_agree? '이메일 수신':'이메일 거부'"
                    @change="emailAlarmAgreeUpdated()"
                ></v-switch>

                <!-- 메뉴 -->
                <div class="d-flex">
                    <!-- 모두 읽음 -->
                    <v-btn
                        width="88"
                        height="34"
                        dark
                        depressed
                        color="#615DFA"
                        class="ml-2 rounded-10"
                        @click="readAll()"
                    >
                        모두 읽음
                    </v-btn>

                    <!-- 선택 읽음 -->
                    <v-btn
                        width="88"
                        height="34"
                        dark
                        depressed
                        color="#23D2E2"
                        class="ml-2 rounded-10"
                        @click="readSelected()"
                    >
                        선택 읽음
                    </v-btn>

                    <!-- 선택 삭제 -->
                    <v-btn
                        width="88"
                        height="34"
                        dark
                        depressed
                        color="#23D2E2"
                        class="ml-2 rounded-10"
                        @click="deleteSelected()"
                    >
                        선택 삭제
                    </v-btn>
                </div>
            </div>

            <!-- 본문 -->
            <div>
                <!-- 알림 내역이 있을 경우 -->
                <div v-if="currentPageList.length">
                    <v-sheet
                        v-for="item in currentPageList" :key="item.id" 
                        class="pa-4 rounded-15 mb-2"
                    >
                        <!-- 게시글 좋아요 추첨 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='like_draw'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2 text-truncate" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 {{item.content}}에서 좋아요 추첨을 했습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래글 관리자 수정요청 -->
                        <div class="d-flex px-4" v-if="item.type.startsWith('거래글 관리자 수정요청')">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-pencil</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer;" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">거래글 관리자 수정요청이 {{item.content}}되었습니다.</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 정보 입력/수정 제보 반려 -->
                        <div class="d-flex px-4" v-if="item.type=='provide_info_rejected'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">정보 입력/수정 제보가 반려되었습니다 (반려 사유는 마이페이지에서 확인가능합니다)</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 오류 제보 반려 -->
                        <div class="d-flex px-4" v-if="item.type=='provide_bug_rejected'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">보내주신 오류제보가 반려되었습니다 (반려 사유는 마이페이지에서 확인가능합니다)</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 이미지 제보 반려 -->
                        <div class="d-flex px-4" v-if="item.type=='provide_image_rejected'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">제보주신 이미지가 반려되었습니다 (반려 사유는 마이페이지에서 확인가능합니다)</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 게시글 이동 -->
                        <div class="d-flex px-4" v-if="item.type=='postMove'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="link_on_new_tab(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="link_on_new_tab(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.content}}</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 문의글 답변 -->
                        <div class="d-flex px-4" v-if="item.type=='contact_answered'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="link_on_new_tab(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-comment-multiple-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="link_on_new_tab(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">작성하신 문의글에 답변이 등록되었습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 다른 이용자로부터 쪽지 -->
                        <div class="d-flex px-4" v-if="item.type=='message'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-comment-multiple-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님으로부터 쪽지가 왔습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 게시글 좋아요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='post_liked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 게시글에 좋아요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 게시글 싫어요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='post_disliked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 게시글에 싫어요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 댓글 좋아요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='comment_liked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 댓글에 좋아요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 댓글 싫어요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='comment_disliked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 댓글에 싫어요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 게시글 댓글 알림 (변경) -->
                        <div class="d-flex px-4" v-if="item.type=='comment'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 댓글을 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 거래글 댓글 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_comment'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 거래글에 댓글을 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 댓글 대댓글 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='sub_comment'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 대댓글을 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 취소 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_canceled'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 거래요청을 취소하였습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 거절 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_refused'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p v-if="item.user_id != $store.state.user_id" class="mb-0" style="font-size:14px; font-weight:600;">
                                    {{item.sender_nickname}}님이 회윈님의 거래요청을 거절하였습니다
                                </p>
                                <p v-if="item.user_id == $store.state.user_id" class="mb-0" style="font-size:14px; font-weight:600;">
                                    {{item.sender_nickname}}님의 거래 요청을 거절하였습니다
                                </p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 수락 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_accepted'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 회윈님의 거래요청을 수락하였습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 받음 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_requested'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 회윈님의 거래글에 거래요청을 하였습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래완료 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_finished'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님과 거래가 완료되었습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래 상대의 리뷰 알림  -->
                        <div class="d-flex px-4" v-if="item.type=='review_writed'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 회원님과의 거래에 리뷰를 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>
                    </v-sheet>
                </div>

                <!-- 알림 내역이 없을 경우 -->
                <div v-if="!alarm_list.length">
                    <v-sheet class="d-flex justify-center pa-10 mb-2 mt-3">
                        <v-sheet color="transparent">
                            <v-icon color="blue" x-large class="d-flex justify-center mb-3">mdi-cancel</v-icon>
                            <p class="mb-0 text-center font-weight-bold">아직 알림 내역이 없습니다</p>
                        </v-sheet>
                    </v-sheet>
                </div>
            </div>

            <!-- 페이징 -->
            <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>

            <!-- 등급 참고용 가이드 안내문구 -->
            <v-sheet class="px-4 py-2 mt-2 rounded-15 d-flex align-center justify-space-between">
                <p class="text-left my-0" style="font-size:14px !important; margin-top:3px !important;">
                    <v-icon color="#ADAFCA" class="mb-3px">
                        mdi-alert-circle-outline
                    </v-icon>
                    <font style="font-size:14px !important;">
                        신규 알림이 보이지 않을 경우 <span class="primary--text">새로고침</span>을 해주시길 바랍니다
                    </font>
                </p>

                <BannerView
                    v-if="$store.state.ads_banner.mypage"
                    type="mypage"
                    width="360"
                    height="46"
                    :round="'rounded-10'"
                />
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="py-1 pr-0 mx-4 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p style="font-size:16px; font-weight:bold; margin-bottom:0px;">알림함</p>
                </div>

                <v-spacer></v-spacer>

                <!-- 공개유무 switch -->
                <v-switch
                    class="font-weight-bold mr-4"
                    v-model="email_alarm_agree"
                    inset
                    :label="email_alarm_agree? '이메일 수신':'이메일 거부'"
                    @change="emailAlarmAgreeUpdated()"
                ></v-switch>

                <v-btn
                    x-small
                    width="34"
                    height="34"
                    dark
                    depressed
                    color="#23D2E2"
                    class="rounded-10"
                    @click="readAll()"
                >
                    <v-icon>mdi-replay</v-icon>
                </v-btn>
            </div>

            <!-- 메뉴 -->
            <div class="d-flex justify-center mx-4">
                <!-- 모두 읽음 -->
                <v-btn
                    width="104"
                    height="34"
                    dark
                    depressed
                    color="#615DFA"
                    class="rounded-10"
                    @click="readAll()"
                >
                    모두 읽음
                </v-btn>

                <!-- 선택 읽음 -->
                <v-btn
                    width="104"
                    height="34"
                    dark
                    depressed
                    color="#23D2E2"
                    class="ml-2 rounded-10"
                    @click="readSelected()"
                >
                    선택 읽음
                </v-btn>

                <!-- 선택 삭제 -->
                <v-btn
                    width="104"
                    height="34"
                    dark
                    depressed
                    color="#23D2E2"
                    class="ml-2 rounded-10"
                    @click="deleteSelected()"
                >
                    선택 삭제
                </v-btn>
            </div>

            <!-- 본문 -->
            <div
                class="mx-auto px-4 mt-4"
            >
                <!-- 알림 내역이 있을 경우 -->
                <div v-if="currentPageList.length">
                    <v-sheet
                        v-for="item in currentPageList" :key="item.id" 
                        class="pa-4 rounded-15 mb-2"
                    >
                        <!-- 게시글 좋아요 추첨 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='like_draw'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2 text-truncate" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 {{item.content}}에서 좋아요 추첨을 했습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="mr-4"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>
                        
                        <!-- 거래글 관리자 수정요청 -->
                        <div class="d-flex px-4" v-if="item.type.startsWith('거래글 관리자 수정요청')">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-pencil</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer;" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">거래글 관리자 수정요청이 {{item.content}}되었습니다.</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 정보 입력/수정 제보 반려 -->
                        <div class="d-flex px-4" v-if="item.type=='provide_info_rejected'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">정보 입력/수정 제보가 반려되었습니다 (반려 사유는 마이페이지에서 확인가능합니다)</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 오류 제보 반려 -->
                        <div class="d-flex px-4" v-if="item.type=='provide_bug_rejected'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">보내주신 오류제보가 반려되었습니다 (반려 사유는 마이페이지에서 확인가능합니다)</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 이미지 제보 반려 -->
                        <div class="d-flex px-4" v-if="item.type=='provide_image_rejected'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">제보주신 이미지가 반려되었습니다 (반려 사유는 마이페이지에서 확인가능합니다)</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 게시글 이동 -->
                        <div class="d-flex px-4" v-if="item.type=='postMove'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="link_on_new_tab(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="link_on_new_tab(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.content}}</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 문의글 답변 -->
                        <div class="d-flex px-4" v-if="item.type=='contact_answered'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="link_on_new_tab(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-comment-multiple-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="link_on_new_tab(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">작성하신 문의글에 답변이 등록되었습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 다른 이용자로부터 쪽지 -->
                        <div class="d-flex px-4" v-if="item.type=='message'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-comment-multiple-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님으로부터 쪽지가 왔습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 게시글 좋아요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='post_liked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 게시글에 좋아요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 게시글 싫어요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='post_disliked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 게시글에 싫어요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 댓글 좋아요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='comment_liked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 댓글에 좋아요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 댓글 싫어요 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='comment_disliked'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 댓글에 싫어요를 눌렀습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 게시글 댓글 알림 (변경) -->
                        <div class="d-flex px-4" v-if="item.type=='comment'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 댓글을 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 자신의 댓글 대댓글 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='sub_comment'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToLink(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-post-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToLink(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 대댓글을 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 취소 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_canceled'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 거래요청을 취소하였습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 거절 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_refused'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p v-if="item.user_id != $store.state.user_id" class="mb-0" style="font-size:14px; font-weight:600;">
                                    {{item.sender_nickname}}님이 회윈님의 거래요청을 거절하였습니다
                                </p>
                                <p v-if="item.user_id == $store.state.user_id" class="mb-0" style="font-size:14px; font-weight:600;">
                                    {{item.sender_nickname}}님의 거래 요청을 거절하였습니다
                                </p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 수락 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_accepted'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 회윈님의 거래요청을 수락하였습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래요청 받음 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_requested'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 회윈님의 거래글에 거래요청을 하였습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래완료 알림 -->
                        <div class="d-flex px-4" v-if="item.type=='trade_finished'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님과 거래가 완료되었습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>

                        <!-- 거래 상대의 리뷰 알림  -->
                        <div class="d-flex px-4" v-if="item.type=='review_writed'">
                            <v-btn
                                fab
                                color="white"
                                class="shadow mr-4"
                                width="60"
                                height="60"
                                depressed
                                style="cursor:pointer"
                                @click="goToMessage(item)"
                            >
                                <v-icon large :color="item.watched? 'grey':'blue'">mdi-credit-card-outline</v-icon>
                            </v-btn>
                            <v-sheet color="transparent ml-2 mt-2" style="cursor:pointer" @click="goToMessage(item)">
                                <p class="mb-0" style="font-size:14px; font-weight:600;">{{item.sender_nickname}}님이 회원님과의 거래에 리뷰를 남겼습니다</p>
                                <p class="mb-0 ml-2px mt-2px" style="font-size:10px; color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>
                            </v-sheet>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                class="ml-1"
                                hide-details
                                :value="item.id"
                                v-model="selected_list"
                            ></v-checkbox>
                        </div>
                    </v-sheet>
                </div>

                <!-- 알림 내역이 없을 경우 -->
                <div v-if="!alarm_list.length">
                    <v-sheet class="d-flex justify-center pa-10 mb-2 mt-3">
                        <v-sheet color="transparent">
                            <v-icon color="blue" x-large class="d-flex justify-center mb-3">mdi-cancel</v-icon>
                            <p class="mb-0 text-center font-weight-bold">아직 알림 내역이 없습니다</p>
                        </v-sheet>
                    </v-sheet>
                </div>
            </div>

            <!-- 페이징 -->
            <v-pagination
                class="mb-3"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>

            <BannerView
                v-if="$store.state.ads_banner.mypage"
                class="mt-3"
                type="mypage"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    },
    data: () => ({
        alarm_list: [],
        selected_list: [],
        currentPageList: [],

        page: 1,
        pageLength: 0,

        email_alarm_agree: false
    }),

    created(){
        this.email_alarm_agree = this.$store.state.email_alarm_agree
    },

    mounted(){
        // 기본 목록
        this.load()

        // 이메일 알림 수신여부 가져오기
        console.log(this.$store.state.email_alarm_agree)
        console.log(this.email_alarm_agree)
    },

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.alarm_list.slice((this.page-1)*8,(this.page)*8)
        }
    },

    methods: {
        ...mapMutations(['update_email_alarm_agree']),

        // 기본 목록
        load(){
            this.$http.post('/api/mypage/alarm/list', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                this.alarm_list = res.data
                this.currentPageList = res.data.slice(0,8)
                this.pageLength = Math.ceil(res.data.length/8)
            })
        },

        // 링크 (쪽지함)
        goToMessage(item){
            this.readLinked(item)
            this.$router.push('/mypage/message?to='+item.sender_nickname)
        },

        // 링크 (일반)
        goToLink(item){
            this.readLinked(item)
            this.$router.push(item.link)
        },

        // 링크 (새탭)
        link_on_new_tab(item){
            this.readLinked(item)
            this.$router.push(item.link)
        },

        // 링크한 알림 읽음 처리
        readLinked(item){
            this.$http.post('/api/mypage/alarm/read/linked', {
                params: {
                    id: item.id
                }
            }).then(() => {
                this.load()
            })
        },

        // 모든 알림 읽음 처리
        readAll(){
            this.$http.post('/api/mypage/alarm/read/all', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(() => {
                alert("모든 알림이 읽음 처리되었습니다.")
                this.load()
            })
        },

        // 선택한 알림 읽음 처리
        readSelected(){
            this.$http.post('/api/mypage/alarm/read/selected', {
                params: {
                    user_id: this.$store.state.user_id,
                    selected_list: this.selected_list
                }
            }).then(() => {
                alert("선택된 알림이 읽음 처리되었습니다.")
                this.load()
            })
        },

        // 선택한 알림 삭제
        deleteSelected(){
            this.$http.post('/api/mypage/alarm/delete/selected', {
                params: {
                    user_id: this.$store.state.user_id,
                    selected_list: this.selected_list
                }
            }).then(() => {
                alert("선택된 알림이 삭제 처리되었습니다.")
                this.load()
            })
        },

        // 이메일 알림 수신여부 변경
        emailAlarmAgreeUpdated(){
            this.update_email_alarm_agree(this.email_alarm_agree)

            this.$http.post("/api/user/update/email_alarm_agree", {
                params: {
                    user_id: this.$store.state.user_id,
                    email_alarm_agree: this.email_alarm_agree
                }
            }).then((res) => {
                console.log(res)
            })
        }
    }
}
</script>
<style scoped>
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style scoped>
.shadow{
    box-shadow: 0px 0px 12px 6px #F1F2F6 !important;
}
</style>