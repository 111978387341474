<template>
    <div class="mx-2">
        <v-sheet
            v-if="target && Object.keys(target).length"
            class="d-flex align-center mx-2 my-2"
        >
            <!-- 프로필 -->
            <Avatar v-if="target.user_id" :user="target.user_id" />
            <v-sheet class="pl-2 pt-2">
                <!-- 닉네임 -->
                <p
                    v-if="target.nickname"
                    class="ml-2px mb-0 font-weight-bold"
                    style="font-size:15px;"
                >
                    {{target.nickname}}
                </p>

                <!-- 별점 -->
                <v-sheet class="text-caption mt-0 mb-1">
                    <v-sheet class="d-flex">
                        <v-rating
                            :value="target.review_rating"
                            half-increments
                            background-color="grey"
                            color="#615DFA"
                            size="13"
                            density="compact"
                            readonly          
                        ></v-rating>
                        <p
                            style="font-size:12px; color:#615DFA; font-weight:bold;"
                            class="mr-1 ml-1 mb-1 pt-1px"
                        >
                            ({{target.review_count}})
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        <v-divider></v-divider>
    </div>
</template>
<script>
import Avatar from "@/components/Avatar"

export default {
    props: ["first_target", "channel_target"],

    components: {
        Avatar
    },
    
    data: () => ({
        target: {}
    }),

    watch: {
        first_target(){
            this.$http.post('/api/mypage/message/target/profile', {
                params: {
                    user_id: this.first_target
                }
            }).then(res => {
                this.target = res.data[0]
            })
        },

        channel_target(){
            this.$http.post('/api/mypage/message/target/profile', {
                params: {
                    user_id: this.channel_target
                }
            }).then(res => {
                this.target = res.data[0]
            })
        }
    }

}
</script>