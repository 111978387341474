<template>
    <v-sheet class="d-flex my-4">
        <!-- <v-btn
            width="100"
            class="mr-2 text-caption font-weight-bold rounded-10"
            color="grey--text text--darken-3 grey lighten-4"
            depressed
            to="/mypage/official_league"
        >
            대회 정보
        </v-btn> -->
        <v-btn
            width="100"
            class="mr-2 text-caption font-weight-bold rounded-10"
            color="grey--text text--darken-3 grey lighten-4"
            depressed
            to="/mypage/password"
        >
            비밀번호 변경
        </v-btn>
        <v-btn
            width="100"
            class="mr-2 text-caption font-weight-bold rounded-10"
            color="grey--text text--darken-3 grey lighten-4"
            depressed
            to="/mypage/address"
        >
            배송지 관리
        </v-btn>
        <v-btn
            width="100"
            class="mr-2 text-caption font-weight-bold rounded-10"
            color="grey--text text--darken-3 grey lighten-4"
            depressed
            to="/mypage/alarm"
        >
            알림함
        </v-btn>
        <v-btn
            width="100"
            class="mr-2 text-caption font-weight-bold rounded-10"
            color="grey--text text--darken-3 grey lighten-4"
            depressed
            to="/mypage/message"
        >
            쪽지함
        </v-btn>
        <v-btn
            width="100"
            class="mr-2 text-caption font-weight-bold rounded-10"
            color="grey--text grey lighten-4"
            depressed
            @click="withdrawal()"
        >
            회원탈퇴
        </v-btn>

        <v-spacer></v-spacer>

        <v-checkbox
            class="mr-3 font-weight-bold"
            style="margin-top:2px;"
            dense
            hide-details
            v-model="myImport.email_agree"
            label="이메일 수신동의"
            @change="emailAgreeChanged()"
        ></v-checkbox>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        myImport: {},
        ready_nick:""
    }),

    mounted(){
        this.$http.post('/api/user/select/specific', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            this.myImport = res.data[0]
        })
    },

    methods: {
        emailAgreeChanged(){
            this.$http.post('/api/user/update/email_agree', {
                params: {
                    user_id: this.$store.state.user_id,
                    email_agree: this.myImport.email_agree
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    if(this.myImport.email_agree){
                        alert("이메일 수신동의가 동의처리되었습니다")
                        this.$http.post('/api/user/select/specific', {
                            params: {
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            if(!res.data[0].email_agree_first){
                                this.$http.post('/api/user/update/email_agree_first', {
                                    params: {
                                        user_id: this.$store.state.user_id
                                    }
                                }).then((res) => {
                                    if(res.data.affectedRows){
                                        // 포인트 내역 기록
                                        this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                                            params: {
                                                user_id: this.$store.state.user_id,
                                                title: "첫 이메일 수신동의",
                                                menu: '회원정보 수정',
                                                activity: '첫 이메일 수신동의',
                                                pay_point: 1000,
                                            }
                                        }).then((res) => {
                                            if(res.data.affectedRows){
                                                alert("첫 이메일 수신동의로 1,000포인트가 지급되었습니다.")
                                            }
                                        })

                                        // 포인트 지급
                                        this.$http.post('/api/mypage/address/point/plus', {
                                            params: {
                                                point: 1000,
                                                user_id: this.$store.state.user_id
                                            }
                                        })

                                        // 첫 수신동의 체크
                                        this.$http.post('/api/user/update/email_agree_first', {
                                            params: {
                                                user_id: this.$store.state.user_id
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }else{
                        alert("이메일 수신동의가 거부처리되었습니다")
                    }
                }
            })
        }
    }
}
</script>
// # v-checkbox
<style scoped>
::v-deep .v-input--checkbox .v-input--selection-controls__input {
    margin-right: 4px;
}

::v-deep .v-input--checkbox .v-label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #40415f;
}  
</style>