<template>
    <v-sheet class="mt-4">
        <v-divider></v-divider>
        <v-tabs
            v-model="tabs"
            fixed-tabs
            color="#23D2E2"
            style="border-bottom:1px solid #e0e0e0;"
        >
            <v-tab v-for="n in menu" :key="n" style="width:100px;">
                <span class="text-caption text-md-body-2 font-weight-bold">{{ n }}</span>
            </v-tab>
        </v-tabs>
        <v-sheet :class="$vuetify.breakpoint.mobile? '':'px-2'">
            <v-tabs-items v-model="tabs">
                <v-tab-item>
                    <ImageList :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <InfoList :user_id="user_id" />
                </v-tab-item>
                <v-tab-item>
                    <FixList :user_id="user_id" />
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>
    </v-sheet>
</template>
<script>
// 이미지 제보, 정보 입력/수정, 오류 제보
import ImageList from '@/views/mypage/components/Home/ListProvide/Image'
import InfoList from '@/views/mypage/components/Home/ListProvide/Info'
import FixList from '@/views/mypage/components/Home/ListProvide/Fix'

export default {
    props: ["user_id"],

    components: {
        ImageList,
        InfoList,
        FixList
    },

    data: () => ({
        tabs: null,
        menu: [
            '이미지 제보',
            '정보 입력/수정',
            '오류 제보'
        ]
    })
}
</script>