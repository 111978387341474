<template>
    <div>
        <!-- 마이페이지 기본 헤더 -->
        <DefaultHeader />

        <v-sheet class="pa-4 rounded-10">
            <!-- 프로필 상단 -->
            <Summary :user_id="$store.state.user_id" />

            <!-- 버튼 -->
            <MypageButtons />

            <!-- 목록 -->
            <v-tabs
                v-model="tabs"
                grow
                color="#23D2E2"
            >
                <v-tab
                    class="text-caption text-md-body-2 font-weight-bold"
                    v-for="n in menu" :key="n"
                >
                    {{ n }}
                </v-tab>
            </v-tabs>
            <v-sheet class="px-2">
                <v-tabs-items v-model="tabs">
                    <v-tab-item>
                        <Point :user_id="$store.state.user_id" />
                    </v-tab-item>
                </v-tabs-items>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
// 마이페이지 기본 헤더
import DefaultHeader from './components/DefaultHeader'

// 프로필 상단
import Summary from '@/views/mypage/components/Summary'

// 버튼
import MypageButtons from './components/Buttons'

// 포인트 내역
import Point from '@/views/mypage/components/Home/ListCommon/point'

export default {
    components: {
        DefaultHeader,
        Summary,
        MypageButtons,
        Point
    },

    data: () => ({
        tabs: null,
        menu: ['포인트 내역'],
        dialog: {
            updateImage: false
        },

        fromCreated: 0,
        postCount: 0,
        commentCount: 0,

        user: {},
        gradeIcon: "",
    }),

    mounted(){
        // 유저 정보 불러오기
        this.load_profile()

        // 작성글 수 불러오기
        this.$http.post('/api/mypage/list/post', {
            params: {
                user_id: this.$store.state.user_id,
            }
        }).then((res) => {
            this.postCount = res.data.length
        })

        // 댓글 수 불러오기
        this.$http.post('/api/mypage/list/comment', {
            params: {
                user_id: this.$store.state.user_id,
            }
        }).then((res) => {
            this.commentCount = res.data.length
        })
    },

    methods: {
        // 회원 정보 불러오기
        load_profile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                this.user = res.data[0]

                // 활동중 일 수 계산
                let today = new Date(Date.now())
                let created = new Date(this.user.created)
                let sum = today - created
                let result = Math.ceil(sum/(24*3600*1000))
                this.fromCreated = result

                // 유저 아이콘
                if (this.user.type == '사용자') {
                    if (this.user.grade == 0) {
                        this.gradeIcon = 'new'
                    } else {
                        this.gradeIcon = this.user.grade
                    }
                } else if(this.user.type == '밴유저') {
                    this.gradeIcon =  'banned'
                } else if (this.user.type == '관리자') {
                    this.gradeIcon = 'admin'
                } else if (this.user.type == '매니저') {
                    this.gradeIcon = 'manager'
                } else if (this.user.type == '운영진') {
                    this.gradeIcon = 'board_manager'
                }
            })
        },

        // 회원 탈퇴
        withdrawal(){
            confirm("정말로 회원탈퇴하시겠습니까?\n작성한 글과 댓글은 탈퇴 후에도 유지되며,\n포인트는 복구되지 않습니다.")
        },

        // 거래내역 조회
        viewTradeLog() {
            this.$router.push('/trade/log?nickname=' + this.user.nickname)
        },

        // 마이페이지 홈으로 이동
        linkToMyPageHome(){
            this.$router.push('/mypage/home')
        },

        // 포인트 내역으로 이동
        linkToPoint() {
            this.$router.push('/mypage/point')
        }
    }
}
</script>